/** override Sidea */

import PropTypes from 'prop-types';
import SliderSlick from 'react-slick';

import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
import SourceProductLinks from 'SourceComponent/ProductLinks/ProductLinks.component';
import { LinkedProductsType, ProductCardPropsType } from 'Type/ProductList.type';

import './ProductLinks.override.style';

/** @namespace Frontend/Component/ProductLinks/Component */
export class ProductLinks extends SourceProductLinks {
    static propTypes = {
        numberOfProductsToDisplay: PropTypes.number.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        linkedProducts: LinkedProductsType.isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        productCardProps: ProductCardPropsType.isRequired,
        productCardFunctions: PropTypes.objectOf(PropTypes.func).isRequired
    };

    renderProductCard = this.renderProductCard.bind(this);

    renderProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            }
        } = this.props;
        const { id = i } = product;

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              siblingsHaveBrands={ siblingsHaveBrands }
              siblingsHavePriceBadge={ siblingsHavePriceBadge }
              siblingsHaveTierPrice={ siblingsHaveTierPrice }
              siblingsHaveConfigurableOptions={ siblingsHaveConfigurableOptions }
              setSiblingsHaveBrands={ setSiblingsHaveBrands }
              setSiblingsHavePriceBadge={ setSiblingsHavePriceBadge }
              setSiblingsHaveTierPrice={ setSiblingsHaveTierPrice }
              setSiblingsHaveConfigurableOptions={ setSiblingsHaveConfigurableOptions }
            />
        );
    }

    renderItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        return items.slice(0, numberOfProductsToDisplay).map(this.renderProductCard);
    }

    renderHeading() {
        const { title } = this.props;

        return (
            <span block="ProductLinks" elem="Title">
                { title }
            </span>
        );
    }

    render() {
        const {
            areDetailsLoaded,
            linkType,
            linkedProducts: { [linkType]: { items } }
        } = this.props;

        const isInfinte = (items.length > 4);

        const settings = {
            dots: false,
            infinite: isInfinte,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            arrows: true,
            responsive: [
                {
                    breakpoint: 811,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        arrows: false
                    }
                }
            ]
        };

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <SliderSlick
                  { ...settings }
                >
                    { this.renderItems() }
                </SliderSlick>
            </ContentWrapper>
        );
    }
}

export default ProductLinks;
