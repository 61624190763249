/** override Sidea */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer
} from 'SourceRoute/ProductPage/ProductPage.container';
import ProductReducer from 'Store/Product/Product.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { debounce } from 'Util/Request';

/** @namespace Frontend/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    areReviewsEnabled: state.ConfigReducer.reviews_are_enabled
});

/** @namespace Frontend/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Frontend/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    static propTypes = {
        ...SourceProductPageContainer.propTypes,
        areReviewsEnabled: PropTypes.bool.isRequired
    };

    state = {
        parameters: {},
        currentProductSKU: '',
        activeProduct: null,
        dynamicSku: ''
    };

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product: {
                sku
            }
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: {
                sku: prevSku
            }
        } = prevProps;

        const { sku: stateSKU } = history?.state?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If product ID was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (sku !== prevSku) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
            this.productDetailsDataLayer();
        }

        this._addToRecentlyViewedProducts();
    }

    productDetailsDataLayer() {
        return null;
    }

    getDynamicSkuForAttributes(activeProduct) {
        const { sku } = activeProduct;

        if (sku !== this.state.dynamicSku) {
            this.setState({ dynamicSku: sku });
        }
    }

    containerProps() {
        const { isMobile, location, areReviewsEnabled } = this.props;
        const { parameters, dynamicSku } = this.state;

        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            activeProduct: this.getActiveProductDataSource(),
            dataSource: this.getDataSource(),
            useEmptyGallerySwitcher: this.getUseEmptyGallerySwitcher(),
            isVariant: this.getIsVariant(),
            isMobile,
            parameters,
            location,
            areReviewsEnabled,
            dynamicSku
        };
    }

    containerFunctions = {
        getLink: this.getLink.bind(this),
        setActiveProduct: this.setActiveProduct.bind(this),
        isProductInformationTabEmpty: this.isProductInformationTabEmpty.bind(this),
        isProductAttributesTabEmpty: this.isProductAttributesTabEmpty.bind(this),
        getDynamicSkuForAttributes: this.getDynamicSkuForAttributes.bind(this)
    };
}

export default withReducers({
    ProductReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)
));
