/** Override Sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CartIcon.style';

/** @namespace Frontend/Component/CartIcon/Component */
export class CartIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="18.601"
              height="18.541"
              viewBox="0 0 18.601 18.541"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Raggruppa_2406" data-name="Raggruppa 2406" transform="translate(2.367 14.202)">
                    <path id="Tracciato_1135" data-name="Tracciato 1135" d="M161.3,276.241a2.17,2.17,0,1,1,2.17-2.17A2.172,2.172,0,0,1,161.3,276.241Zm0-3.156a.986.986,0,1,0,.986.986A.987.987,0,0,0,161.3,273.085Z" transform="translate(-159.13 -271.902)" />
                </g>
                <g id="Raggruppa_2407" data-name="Raggruppa 2407" transform="translate(13.412 14.202)">
                    <path id="Tracciato_1136" data-name="Tracciato 1136" d="M266.3,276.241a2.17,2.17,0,1,1,2.17-2.17A2.172,2.172,0,0,1,266.3,276.241Zm0-3.156a.986.986,0,1,0,.986.986A.987.987,0,0,0,266.3,273.085Z" transform="translate(-264.13 -271.902)" />
                </g>
                <g id="Raggruppa_2408" data-name="Raggruppa 2408" transform="translate(4.536 14.202)">
                    <rect id="Rettangolo_1879" data-name="Rettangolo 1879" width="11.046" height="1.183" />
                </g>
                <g id="Raggruppa_2409" data-name="Raggruppa 2409" transform="translate(3.245 3.159)">
                    <path id="Tracciato_1137" data-name="Tracciato 1137" d="M182.015,176h-13.59V174.82h12.531l.577-5.2-14.048-1.514.127-1.177,15.229,1.641Z" transform="translate(-167.485 -166.934)" />
                </g>
                <g id="Raggruppa_2410" data-name="Raggruppa 2410">
                    <path id="Tracciato_1138" data-name="Tracciato 1138" d="M140.647,152.349l-1.584-14.263h-2.429V136.9h3.488l1.7,15.316Z" transform="translate(-136.634 -136.902)" />
                </g>
            </svg>
        );
    }
}

export default CartIcon;
