/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { LocationType } from 'Type/Router.type';

import { AmastyAutomaticRelatedProductsContext } from '../../context/AmastyAutomaticRelatedProducts';
import PackList from './PackList.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/PackList/Container/PackListContainer */
export class PackListContainer extends PureComponent {
    static propTypes = {
        location: LocationType.isRequired
    };

    static contextType = AmastyAutomaticRelatedProductsContext;

    containerFunctions = {};

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps;
        const { location } = this.props;

        if (location !== prevLocation) {
            this.fetchPackList();
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoading: false
        };

        this.fetchPackList();
    }

    getCurrentPage() {
        const { location: { search } } = this.props;

        return new URLSearchParams(search).get('page');
    }

    async fetchPackList() {
        const { fetchPackList } = this.context;
        const currentPage = this.getCurrentPage() || 1;

        this.setState({ isLoading: true });
        await fetchPackList(currentPage);
        this.setState({ isLoading: false });
    }

    containerProps() {
        const { packList, packListTotalPages } = this.context;
        const { isLoading } = this.state;

        return {
            packList,
            packListTotalPages,
            isLoading
        };
    }

    render() {
        return (
            <PackList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(PackListContainer);
