/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CART_POPUP_ID } from '../component/BundlePack/BundlePack.config';

export const addPopupReducerToMapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        popup: state.PopupReducer?.popupPayload
    };
};

export const removeZoomOnPopup = (args, callback, instance) => {
    const { popup } = instance.props;

    const isProductPagePopup = Object.keys(popup).filter((key) => {
        const newKey = key.replace(/[0-9]/g, '');
        return newKey === CART_POPUP_ID;
    }).length > 0;

    if (!isProductPagePopup) {
        return callback(...args);
    }

    // eslint-disable-next-line no-param-reassign
    instance.state.isImageZoomPopupActive = false;

    return callback(...args);
};

export default {
    'Component/ProductGallery/Container/mapStateToProps': {
        function: addPopupReducerToMapStateToProps
    },
    'Component/ProductGallery/Container': {
        'member-function': {
            render: removeZoomOnPopup
        }
    }
};
