/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import ProductCard from 'Component/ProductCard/ProductCard.component';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer
} from 'Component/ProductCard/ProductCard.container';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { fetchMutation } from 'Util/Request';

import ClickMutationQuery from '../../query/ClickMutation.query';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductCardWithClickEvent/Container */
export class ProductCardWithClickEventContainer extends ProductCardContainer {
    static propTypes = {
        ...ProductCardContainer.propTypes,
        blockId: PropTypes.number.isRequired
    };

    static defaultProps = {
        ...ProductCardContainer.defaultProps
    };

    containerFunctions = {
        ...this.containerFunctions
    };

    async addToCart() {
        this.updateSelectedValues();
        const { showError, product: { id }, blockId } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();

        await addProductToCart({ products, cartId })
            .catch(
                /** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductCardWithClickEvent/Container/ProductCardWithClickEventContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );

        await fetchMutation(ClickMutationQuery.getQuery(blockId, id));
    }

    containerProps() {
        return {
            ...super.containerProps()
        };
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardWithClickEventContainer);
