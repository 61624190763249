/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import Messages from '../../component/Messages';

export const addAmastyMessagesToRender = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled, isMessageInCartEnabled } = {} } = instance.props;

    if (!isEnabled || !isMessageInCartEnabled) {
        return (callback(...args));
    }

    const Component = callback(...args);

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                const lastIndex = Component.props.children.length
                    ? Component.props.children.length - 1
                    : 0;

                if (i === lastIndex) {
                    return child;
                }

                return (
                    <>
                         <Messages />
                         { child }
                    </>
                );
            }
        )
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render: addAmastyMessagesToRender
        }
    }
};
