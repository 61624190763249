/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Related from '../../component/Related';
import {
    RELATED_CATEGORY,
    RELATED_POSITION_BOTTOM,
    RELATED_POSITION_TOP,
    RELATED_TO_SIDEBAR
} from '../../component/Related/Related.config';
import { getPositions } from '../../util/AmastyAutomaticRelatedProducts';

export const addAmastyRelatedToOverlay = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled } = {}, relatedProducts } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_CATEGORY);

    if (!positionRelateTo) {
        return callback(...args);
    }

    if (positionRelateTo === RELATED_TO_SIDEBAR && positionPlace === RELATED_POSITION_BOTTOM) {
        return (
            <>
                { callback(...args) }
                <Related relatedType={ RELATED_CATEGORY } />
            </>
        );
    }

    if (positionRelateTo === RELATED_TO_SIDEBAR && positionPlace === RELATED_POSITION_TOP) {
        return (
            <>
                <Related relatedType={ RELATED_CATEGORY } />
                { callback(...args) }
            </>
        );
    }

    return callback(...args);
};

export default {
    'Component/CategoryFilterOverlay/Component': {
        'member-function': {
            renderFilters: addAmastyRelatedToOverlay
        }
    }
};
