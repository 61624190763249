/** extend Sidea */

import {
    HeartIcon as SourceHeartIcon
} from 'SourceComponent/HeartIcon/HeartIcon.component';

/** @namespace Frontend/Component/HeartIcon/Component */
export class HeartIcon extends SourceHeartIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="HeartIcon"
              mods={ { isActive } }
              width="31.58"
              height="27.061"
              viewBox="0 0 31.58 27.061"
              stroke="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g transform="translate(-506.773 -265.978)">
                    <path d="M1368.205,2258.732h-2.016a6.511,6.511,0,0,0-6.383-6.617,7.091,7.091,0,0,0-6.452,4.211h-1.878a7.092,7.092,0,0,0-6.452-4.211,6.511,6.511,0,0,0-6.383,6.617h-2.016a8.567,8.567,0,0,1,8.4-8.706,9.286,9.286,0,0,1,7.391,3.793,9.286,9.286,0,0,1,7.391-3.793A8.567,8.567,0,0,1,1368.205,2258.732Z" transform="translate(-829.852 -1984.048)" />
                    <g transform="translate(506.773 274.239)">
                        <path d="M1352.415,2315.7l-.537-.341c-5.7-3.613-15.253-10.816-15.253-18.459h2.016c0,6.642,9.231,13.449,13.774,16.4,4.543-2.947,13.774-9.754,13.774-16.4h2.016c0,7.643-9.557,14.845-15.253,18.459Z" transform="translate(-1336.625 -2296.901)" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default HeartIcon;
