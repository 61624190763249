/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import CartIcon from 'Component/CartIcon';
import ContentWrapper from 'Component/ContentWrapper';
import Popup from 'Component/Popup/Popup.container';
import { ProductType } from 'Type/ProductList.type';

import BundlePackItem from '../BundlePackItem';
import EqualsIcon from '../EqualsIcon';
import ProductsPopUp from '../ProductsPopUp';
import { CART_POPUP_ID } from './BundlePack.config';

import './BundlePack.style';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePack/Component */
export class BundlePackComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        packKey: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        itemClass: PropTypes.string.isRequired,
        discount: PropTypes.string.isRequired,
        finalPrice: PropTypes.string.isRequired,
        handleCheckedProducts: PropTypes.func.isRequired,
        isOnlyOne: PropTypes.bool.isRequired,
        isOverConditionalDiscount: PropTypes.bool.isRequired,
        handleButtonClick: PropTypes.func.isRequired,
        checkedProducts: PropTypes.arrayOf(ProductType).isRequired,
        multiRowClass: PropTypes.string.isRequired,
        isAdding: PropTypes.bool.isRequired
    };

    renderTitle() {
        const { title } = this.props;

        return (
            <div block="BundlePack" elem="Title">
                <h2>
                    { title }
                </h2>
            </div>
        );
    }

    renderPlus(idx) {
        const { items } = this.props;

        if (idx === items.length - 1) {
            return null;
        }

        return (
            <span block="BundlePack" elem="Plus">
                <AddIcon />
            </span>
        );
    }

    renderEqual() {
        const { multiRowClass } = this.props;
        const equal = `Equal${ multiRowClass}`;

        return (
            <span block="BundlePack" elem={ equal }>
                <EqualsIcon />
            </span>
        );
    }

    renderItem = (item, idx) => {
        const {
            itemClass,
            handleCheckedProducts,
            isOnlyOne,
            isOverConditionalDiscount
        } = this.props;

        return (
            <Fragment key={ idx }>
                <div
                  key={ idx }
                  block="BundlePack"
                  elem={ `Item${itemClass}` }
                  id={ idx }
                >
                    <BundlePackItem
                      item={ item }
                      handleCheckedProducts={ handleCheckedProducts }
                      isOnlyOne={ isOnlyOne }
                      isOverConditionalDiscount={ isOverConditionalDiscount }
                    />
                </div>
                { this.renderPlus(idx) }
            </Fragment>
        );
    };

    renderPackList() {
        const { items, multiRowClass } = this.props;
        const list = `List${ multiRowClass}`;

        return (
            <div block="BundlePack" elem={ list }>
                { items.map(this.renderItem) }
            </div>
        );
    }

    renderDiscountSummary() {
        const { discount, multiRowClass } = this.props;
        const finalDiscount = `FinalDiscount${ multiRowClass}`;

        return (
            <div block="BundlePack" elem={ finalDiscount }>
                { __('Save ') }
                <span className="price">
                    { discount }
                </span>
            </div>
        );
    }

    renderFinalPrice() {
        const { finalPrice, multiRowClass } = this.props;
        const finalPriceElem = `FinalPrice${ multiRowClass}`;

        return (
            <div block="BundlePack" elem={ finalPriceElem }>
                <span className="price">
                    { finalPrice }
                </span>
            </div>
        );
    }

    renderPriceWrapper() {
        return (
            <div block="BundlePack" elem="PriceWrapper">
                { this.renderDiscountSummary() }
                { this.renderFinalPrice() }
                { this.renderAddToCart() }
            </div>
        );
    }

    renderCartIcon() {
        return <CartIcon />;
    }

    renderAddToCart() {
        const { handleButtonClick, isAdding } = this.props;
        const text = isAdding ? __('Adding...') : __('Add to cart');

        return (
            <button
              onClick={ handleButtonClick }
              block="Button AddToCart"
              disabled={ isAdding }
            >
                { this.renderCartIcon() }
                <span>{ text }</span>
            </button>
        );
    }

    renderContent() {
        return (
            <div block="BundlePack" elem="ContentWrapper">
                { this.renderPackList() }
                { this.renderEqual() }
                { this.renderPriceWrapper() }
            </div>
        );
    }

    renderWrapper() {
        const { packKey } = this.props;
        return (
            <ContentWrapper>
                <div block="BundlePack" elem="Wrapper" id={ `wrapper-${packKey}` }>
                    { this.renderTitle() }
                    { this.renderContent() }
                </div>
            </ContentWrapper>
        );
    }

    renderCartPopup() {
        const { checkedProducts, items } = this.props;

        return (
            <Popup
              id={ CART_POPUP_ID }
              mix={ { block: 'ProductReviews', elem: 'Popup' } }
            >
                <ProductsPopUp products={ checkedProducts } items={ items } />
            </Popup>
        );
    }

    render() {
        return (
            <div block="BundlePack">
                { this.renderWrapper() }
                { this.renderCartPopup() }
            </div>
        );
    }
}

export default BundlePackComponent;
