/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import Related from '../../component/Related';
import {
    RELATED_CATEGORY,
    RELATED_POSITION_BOTTOM,
    RELATED_POSITION_TOP,
    RELATED_TO_CONTENT
} from '../../component/Related/Related.config';
import { getPositions } from '../../util/AmastyAutomaticRelatedProducts';

export const addAmastyRelatedToCategory = (args, callback, instance) => {
    const {
        amastyARPConfig: {
            isEnabled
        } = {},
        relatedProducts,
        fetchRelatedProducts,
        category: { id }
    } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    console.log('passo dalla categoria', id, RELATED_CATEGORY);
    fetchRelatedProducts(id, RELATED_CATEGORY);
    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_CATEGORY);

    if (!positionRelateTo) {
        return callback(...args);
    }

    const Component = callback(...args);

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                const lastIndex = Component.props.children.length
                    ? Component.props.children.length - 1
                    : 0;

                if (i === lastIndex) {
                    if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_BOTTOM) {
                        return (
                            <>
                                { child }
                                <Related relatedType={ RELATED_CATEGORY } id={ id } />
                            </>
                        );
                    }

                    return child;
                }

                if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_TOP) {
                    return (
                        <>
                            <Related relatedType={ RELATED_CATEGORY } id={ id } />
                            { child }
                        </>
                    );
                }

                return child;
            }
        )
    );
};

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render: addAmastyRelatedToCategory
        }
    }
};
