/** override Sidea */

import PropTypes from 'prop-types';

import AddToCartPopup from 'Component/AddToCartPopup';
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';
import { MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout.type';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Frontend/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        product: ProductType,
        isDisabled: PropTypes.bool.isRequired,
        isAdding: PropTypes.bool.isRequired,
        handleButtonClick: PropTypes.func.isRequired,
        price: PropTypes.number.isRequired,
        priceType: PropTypes.number.isRequired,
        tierPrices: PropTypes.objectOf(PropTypes.string),
        quantity: PropTypes.number.isRequired,

        isIconEnabled: PropTypes.bool.isRequired,
        mix: MixType.isRequired,
        layout: LayoutType.isRequired
    };

    /* setto lo state per prendere il valore del manufacturer dal prodotto padre */
    __construct(props) {
        super.__construct(props);
        this.state = {
            query: ''
        };
    }

    renderBrandName() {
        const {
            product: {
                attributes: { manufacturer: { attribute_value: brands, attribute_options } = {} } = {}
            }
        } = this.props;

        if (brands) {
            const brandName = Object.keys(attribute_options).map((item) => (
                attribute_options[item].label
            ));

            this.setState({ query: brandName.toString() }); /* setto lo state con il valore del manufacturer dal prodotto padre */
        }

        return null;
    }

    /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
    render() {
        const {
            mix,
            layout,
            isDisabled,
            isAdding,
            handleButtonClick,
            quantity,
            product,
            price,
            priceType,
            tierPrices
        } = this.props;

        const marchio = this.state.query;

        return (
            <>
                <button
                  onClick={ handleButtonClick }
                  block="Button AddToCart"
                  mix={ mix }
                  mods={ { layout } }
                  disabled={ isDisabled || isAdding }
                >
                    <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span>
                </button>
                { this.renderBrandName() }
                <AddToCartPopup
                  product={ product }
                  quantity={ quantity }
                  brand={ marchio }
                  priceValue={ price }
                  priceType={ priceType }
                  tierPrices={ tierPrices }
                />
            </>
        );
    }
}

export default AddToCartComponent;
