/** override Sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ADD_TO_CART_POPUP_ID } from 'Component/AddToCartPopup/AddToCartPopup.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Popup from 'Component/Popup';
import ProductPrice from 'Component/ProductPrice';
import { CART_URL } from 'Route/CartPage/CartPage.config';
import { ProductType } from 'Type/ProductList.type';

import './AddToCartPopup.style';

/** @namespace Frontend/Component/AddToCartPopup/Component */
export class AddToCartPopupComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        brand: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        priceValue: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        priceTiers: PropTypes.objectOf(PropTypes.string).isRequired,
        popupRef: PropTypes.any.isRequired,
        handleShoppingClick: PropTypes.func.isRequired
    };

    renderPicture() {
        const { product: { name, thumbnail } } = this.props;

        if (thumbnail) {
            return (
            <Image
              src={ thumbnail.url }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture' } }
            />
            );
        }

        return (
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
        );
    }

    renderProductName() {
        const { product: { name } } = this.props;

        return (
            <p
              block="CartItem"
              elem="Heading"
            >
                { name }
            </p>
        );
    }

    renderBrandName() {
        const { brand } = this.props;

        if (brand) {
            return (
                <div
                  block="CartItem"
                  elem="Brand"
                >
                    { brand }
                </div>
            );
        }

        return null;
    }

    renderQuantity() {
        const { quantity } = this.props;

        return (
            <p
              block="CartItem"
              elem="Quantity"
            >
                { __('Quantity: %s', quantity) }
            </p>
        );
    }

    renderProductPrice() {
        const {
            priceValue,
            priceTiers,
            type
        } = this.props;

        if (!priceValue) {
            return null;
        }

        return (
            <ProductPrice
              meta
              price={ priceValue }
              priceType={ type }
              tierPrices={ priceTiers }
              mix={ { block: 'CartItem', elem: 'Price' } }
            />
        );
    }

    render() {
        const {
            popupRef,
            handleShoppingClick
        } = this.props;

        return (
            <Popup
              popupRef={ popupRef }
              id={ `${ADD_TO_CART_POPUP_ID}` }
              mix={ { block: 'AddToCart', elem: 'Popup RightSidebar' } }
            >
                <div block="AddToCartPopup" elem="Content">
                    <h2>{ __('The product was added to your cart') }</h2>

                    <div block="AddToCartPopup" elem="Product">
                        { this.renderPicture() }
                        { this.renderBrandName() }
                        { this.renderProductName() }
                        { this.renderQuantity() }
                        { this.renderProductPrice() }
                    </div>

                    <div block="AddToCartPopup" elem="Actions">
                        <Link
                          block="Button primary"
                          to={ CART_URL }
                        >
                            { __('Go to cart') }
                        </Link>
                        <button className="Button secondary" onClick={ handleShoppingClick }>
                            <span>{ __('Continue Shopping') }</span>
                        </button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default AddToCartPopupComponent;
