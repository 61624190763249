/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SliderSlick from 'react-slick';

import ContentWrapper from 'Component/ContentWrapper';

import ProductCardWithClickEvent from '../ProductCardWithClickEvent';
import { AMASTY_BLOCK_MAP, RELATED_CATEGORY, RELATED_TO_SIDEBAR } from './Related.config';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Related/Component */
export class RelatedComponent extends PureComponent {
    static propTypes = {
        relatedProduct: PropTypes.shape({
            blockTitle: PropTypes.string,
            position: PropTypes.string
        }).isRequired,
        relatedType: PropTypes.string.isRequired
    };

    renderTitle() {
        const { relatedProduct: { blockTitle = '' } = {} } = this.props;

        return (
            <h2 block="ProductLinks" elem="Title">
                { blockTitle }
            </h2>
        );
    }

    renderItem = (item, idx) => {
        const { relatedProduct: { position } } = this.props;
        const pos = position.split('_')[0];
        const blockId = AMASTY_BLOCK_MAP[pos];

        return (
            <ProductCardWithClickEvent
              block="ProductLinks"
              elem="Card"
              product={ item }
              key={ idx }
              blockId={ blockId }
            />
        );
    };

    renderList() {
        const { relatedProduct: { items = [] } = {} } = this.props;
        if (items.length === 0) {
            return null;
        }

        return items.map(this.renderItem);
    }

    renderProduct() {
        const { relatedProduct: { items = [] } = {} } = this.props;

        // eslint-disable-next-line no-magic-numbers
        const isInfinte = (items.length > 4);

        const settings = {
            dots: false,
            infinite: isInfinte,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            arrows: true,
            responsive: [
                {
                    breakpoint: 811,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        arrows: false
                    }
                }
            ]
        };

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
              { this.renderTitle() }
              <SliderSlick
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                { ...settings }
              >
                  { this.renderList() }
              </SliderSlick>
            </ContentWrapper>
        );
    }

    renderCategory() {
        return (
            <div block="Related">
                { this.renderTitle() }
                <ul block="ProductLinks" elem="ListCategory">
                    { this.renderList() }
                </ul>
            </div>
        );
    }

    render() {
        const { relatedType, relatedProduct: { position = '' } } = this.props;
        const positionRelated = position.split('_')[1];
        const render = relatedType === RELATED_CATEGORY && positionRelated === RELATED_TO_SIDEBAR
            ? this.renderCategory()
            : this.renderProduct();

        return render;
    }
}

export default RelatedComponent;
