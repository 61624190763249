/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import Popup from 'Component/Popup/Popup.container';

import BundlePackComponent from '../BundlePack/BundlePack.component';
import { CART_POPUP_ID } from '../BundlePack/BundlePack.config';
import ProductsPopUp from '../ProductsPopUp';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductBundlePack/Component/ProductBundlePackComponent */
export class ProductBundlePackComponent extends BundlePackComponent {
    static propTypes = {
        ...BundlePackComponent.PropTypes,
        index: PropTypes.number.isRequired
    };

    renderCartPopup() {
        const {
            products,
            checkedProducts,
            items,
            index
        } = this.props;

        const compatibleProducts = checkedProducts.length > 0
            ? checkedProducts
            : products;

        return (
            <Popup
              id={ CART_POPUP_ID + index }
              mix={ { block: 'ProductReviews', elem: 'Popup' } }
            >
                <ProductsPopUp products={ compatibleProducts } items={ items } />
            </Popup>
        );
    }

    render() {
        return (
            <div block="BundlePack">
                { this.renderWrapper() }
                { this.renderCartPopup() }
            </div>
        );
    }
}

export default ProductBundlePackComponent;
