/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Messages from './Messages.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Messages/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartMessage: state.CartReducer?.cartTotals?.cartMessage
});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Messages/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Messages/Container/MessagesContainer */
export class MessagesContainer extends PureComponent {
    static propTypes = {
        cartMessage: PropTypes.shape({
            packId: PropTypes.number,
            message: PropTypes.string
        })
    };

    static defaultProps = {
        cartMessage: {
            packId: 0,
            message: ''
        }
    };

    containerFunctions = {};

    containerProps() {
        const {
            cartMessage: {
                packId = 0,
                message = ''
            } = {}
        } = this.props;

        return {
            packId,
            message
        };
    }

    render() {
        const { cartMessage: { packId } } = this.props;

        if (!packId) {
            return null;
        }

        return (
            <Messages
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
