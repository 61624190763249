/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CartBundleQuery from '../../query/CartBundle.query';
import CartMessageQuery from '../../query/CartMessage.query';

export const addCartMessages = (args, callback) => [
    ...callback(...args),
    CartMessageQuery.getQuery(),
    CartBundleQuery.getQuery()
];

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartTotalsFields: addCartMessages
        }
    }
};
