/** override Sidea */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { ProductType } from 'Type/ProductList.type';

import AddToCartPopup from './AddToCartPopup.component';

/** @namespace Frontend/Component/AddToCartPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    /* wishlistItems: state.WishlistReducer.productsInWishlist */
});

/** @namespace Frontend/Component/AddToCartPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    /* addProduct: options => CartDispatcher.addProductToCart(dispatch, options), */
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Frontend/Component/AddToCartPopup/Container */
export class AddToCartPopupContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        quantity: PropTypes.number.isRequired,
        brand: PropTypes.string.isRequired,
        priceValue: PropTypes.number.isRequired,
        currencyCode: PropTypes.string,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired
    };

    static defaultProps = {
        currencyCode: null
    };

    containerFunctions = {
        handleShoppingClick: this.handleShoppingClick.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.popupRef = createRef();
    }

    _getProduct() {
        const { product } = this.props;
        return product;
    }

    handleShoppingClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay
        } = this.props;

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    containerProps() {
        const {
            brand,
            quantity,
            currencyCode,
            priceValue
        } = this.props;

        return {
            brand,
            quantity,
            currencyCode,
            priceValue,
            popupRef: this.popupRef,
            product: this._getProduct()
        };
    }

    render() {
        return (
            <AddToCartPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartPopupContainer);
