/** override sidea */

import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import {
    ProductInformation as SourceProductInformation
} from 'SourceComponent/ProductInformation/ProductInformation.component';

import './ProductInformation.style';

/** @namespace Frontend/Component/ProductInformation/Component */
export class ProductInformation extends SourceProductInformation {
    renderContent() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('Description') : '';

        return (
            <ExpandableContent
              // show placeholder if the details are not loaded
              heading={ heading }
              mix={ { block: 'ProductInformation', elem: 'Content' } }
            >
                { this.renderDescription() }
            </ExpandableContent>
        );
    }

    renderIsHTMLWhiteSpaceOrUndefined(htmlString) {
        if (!htmlString || htmlString.trim() === '') {
            return true;
        }

        const parser = new DOMParser();
        const document = parser.parseFromString(htmlString.trim(), 'text/html');

        if (document.body.children.length === 0) {
            return false;
        }

        const elementsWithContent = Array.from(document.body.children).filter(
            (element) => element.innerHTML !== '' || element.nodeName === 'IMG'
        );

        return elementsWithContent.length === 0;
    }

    render() {
        const {
            areDetailsLoaded,
            product: {
                description: { html } = {}
            }
        } = this.props;

        if (this.renderIsHTMLWhiteSpaceOrUndefined(html) && areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              label="Product information"
              mix={ { block: 'ProductInformation' } }
              wrapperMix={ { block: 'ProductInformation', elem: 'Wrapper' } }
            >
                { this.renderContent() }
            </ContentWrapper>
        );
    }
}

export default ProductInformation;
