/** override sidea */
import PropTypes from 'prop-types';

import HeartIcon from 'Component/HeartIcon';
import SourceProductWishlistButton from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';
import { MixType } from 'Type/Common.type';
import { MagentoProductType } from 'Type/ProductList.type';

import './ProductWishlistButton.override.style';

/** @namespace Frontend/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {
    static propTypes = {
        magentoProduct: PropTypes.arrayOf(MagentoProductType).isRequired,

        isLoading: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isInWishlist: PropTypes.bool,
        isSignedIn: PropTypes.bool.isRequired,

        addToWishlist: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,

        mix: MixType
    };

    static defaultProps = {
        mix: {},
        isLoading: false,
        isDisabled: false,
        isInWishlist: false
    };

    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
                <span>{ __('Add to Wishlist') }</span>
            </button>
        );
    }
}

export default ProductWishlistButton;
