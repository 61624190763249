/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getIndexedProducts } from 'Util/Product';

import { AmastyAutomaticRelatedProductsContext } from '../../context/AmastyAutomaticRelatedProducts';
import Related from './Related.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Related/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productId: state.ProductReducer?.product?.id,
    categoryId: state.CategoryReducer?.category?.id
});
/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Related/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Related/Container */
export class RelatedContainer extends PureComponent {
    static propTypes = {
        productId: PropTypes.number,
        relatedType: PropTypes.string,
        categoryId: PropTypes.number,
        id: PropTypes.number
    };

    static defaultProps = {
        relatedType: 'product',
        productId: 0,
        categoryId: 0,
        id: 0
    };

    static contextType = AmastyAutomaticRelatedProductsContext;

    containerFunctions = {};

    componentDidMount() {
        console.log('passo dal mount nei related');
        this.getRelatedProducts();
    }

    __construct(props) {
        super.__construct(props);
    }

    async getRelatedProducts() {
        const { fetchRelatedProducts } = this.context;
        const { relatedType, id } = this.props;

        console.log('passo di qua dal related component construct', id, relatedType);

        if (!id) {
            return;
        }

        await fetchRelatedProducts(id, relatedType);
    }

    getRelatedProduct() {
        const { relatedProducts } = this.context;
        const { relatedType } = this.props;

        if (!relatedProducts) {
            return {};
        }

        const relatedProduct = relatedProducts.find((item) => item.type === relatedType);
        const { blockTitle, position, items } = relatedProduct || {};

        const newItems = getIndexedProducts(items);

        const product = {
            blockTitle,
            items: newItems,
            position
        };

        return product;
    }

    containerProps() {
        const { relatedType } = this.props;

        return {
            relatedProduct: this.getRelatedProduct(),
            relatedType
        };
    }

    render() {
        return (
            <Related
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedContainer);
