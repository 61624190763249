/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const RELATED_CMS_TYPE = 'AmastyRelated';
export const RELATED_PRODUCT = 'product';
export const RELATED_CATEGORY = 'category';
export const RELATED_CART = 'cart';
export const RELATED_TO_SIDEBAR = 'sidebar';
export const RELATED_TO_CONTENT = 'content';
export const RELATED_POSITION_TOP = 'top';
export const RELATED_POSITION_BOTTOM = 'bottom';
export const RELATED_TO_BEFORE = 'before';
export const RELATED_POSITION_TAB = 'tab';
export const RELATED_TO_AFTER = 'after';
export const RELATED_POSITION_UPSELL = 'upsell';
export const RELATED_POSITION_RECOMMENDED = 'related';
export const RELATED_POSITION_CROSSEL = 'crosssel';
export const RELATED_CART_CACHE = 1000;
export const RELATED_ONE_MINUTE_IN_SECONDS = 60;
export const AMASTY_BLOCK_MAP = {
    [RELATED_CATEGORY]: 1,
    [RELATED_CART]: 2,
    [RELATED_PRODUCT]: 3
};
