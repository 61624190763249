/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    ProductPageContainer
} from 'Route/ProductPage/ProductPage.container';
import ProductReducer from 'Store/Product/Product.reducer';
import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';

import BundleProductsPage from './BundleProductsPage.component';

export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Meta/Meta.dispatcher'
);

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundleProductsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    metaTitle: state.MetaReducer.title,
    isMobile: state.ConfigReducer.device.isMobile,
    store: state.ConfigReducer.code,
    areReviewsEnabled: state.ConfigReducer.reviews_are_enabled
});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundleProductsPage/Container/BundleProductsPageContainer */
export class BundleProductsPageContainer extends ProductPageContainer {
    static propTypes = {
        ...ProductPageContainer.PropTypes,
        setActiveProducts: PropTypes.func.isRequired,
        setActiveParameters: PropTypes.func.isRequired,
        setActiveQuantities: PropTypes.func.isRequired
    };

    state = {
        currentProductSKU: '',
        activeProduct: null
    };

    containerFunctions = {
        getLink: this.getLink.bind(this),
        setActiveProduct: this.setActiveProduct.bind(this),
        setActiveParameters: this.setActiveParameters.bind(this),
        setActiveQuantities: this.setActiveQuantities.bind(this)
    };

    componentDidMount() {
        /**
         * Always request product information. In this case we will have updated data.
         * Service worker will return previous information and updated new information
         * through broadcast.
         */
        this.requestProduct();

        scrollToTop();
    }

    setActiveProduct(product) {
        const { setActiveProducts } = this.props;
        setActiveProducts(product);
        this.setState({ activeProduct: product });
    }

    setActiveParameters(parameters, product) {
        const { setActiveParameters } = this.props;
        setActiveParameters(parameters, product);
    }

    setActiveQuantities(quantity, product) {
        const { setActiveQuantities } = this.props;

        setActiveQuantities(quantity, product);
    }

    containerProps() {
        return {
            ...super.containerProps()
        };
    }

    requestProduct() {
        const { productSKU } = this.props;
        const { currentProductSKU } = this.state;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });
    }

    render() {
        return (
            <BundleProductsPage
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    ProductReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BundleProductsPageContainer)
));
