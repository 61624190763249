/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import { ItemShape } from 'Type/ProductList.type';

export const ItemType = PropTypes.shape({
    id: PropTypes.string,
    product: ItemShape,
    productUrl: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    imageLabel: PropTypes.string,
    productQty: PropTypes.number,
    discountHtml: PropTypes.string,
    reviewsCount: PropTypes.number,
    reviewSummary: PropTypes.number,
    isInCart: PropTypes.bool,
    isMainProduct: PropTypes.bool,
    productPriceHtml: PropTypes.string
});

export const BundleType = PropTypes.shape({
    title: PropTypes.string,
    packKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    itemClass: PropTypes.string,
    discount: PropTypes.string,
    conditionalDiscount: PropTypes.number,
    finalPrice: PropTypes.string,
    childIds: PropTypes.string,
    multiRowClass: PropTypes.string,
    totalPages: PropTypes.number
});
