/** component sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MapIcon.style';

/** @namespace Frontend/Component/MapIcon/Component */
export class MapIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="MapIcon"
              mods={ { isActive } }
              width="15.031"
              height="24"
              viewBox="0 0 15.031 24"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path id="Esclusione_5" data-name="Esclusione 5" d="M6.828,23a.655.655,0,0,1-.591-.373L.758,10.541A7.528,7.528,0,0,1,1,3.5,7.117,7.117,0,0,1,3.25,1.086,6.563,6.563,0,0,1,6.5.014C6.665,0,6.817,0,6.964,0h.053c.19,0,.36.011.519.023a6.566,6.566,0,0,1,3.227,1.13A7.124,7.124,0,0,1,12.972,3.6,7.532,7.532,0,0,1,13.1,10.65L7.413,22.636A.646.646,0,0,1,6.828,23ZM6.956,4.171a2.918,2.918,0,0,0-2.089.879,3.042,3.042,0,0,0-.879,2.128A3.005,3.005,0,0,0,6.93,10.237h.027a2.915,2.915,0,0,0,2.088-.879,3.081,3.081,0,0,0,.027-4.28,2.92,2.92,0,0,0-2.091-.907Z" transform="translate(0.56 0.5)" fill="#fff" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
            </svg>
        );
    }
}

export default MapIcon;
