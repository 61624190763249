/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ItemType } from '../../type/AmastyAutomaticRelatedProducts.type';
import BundlePackItem from './BundlePackItem.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePackItem/Container/BundlePackItemContainer */
export class BundlePackItemContainer extends PureComponent {
    static propTypes = {
        item: ItemType,
        handleCheckedProducts: PropTypes.func.isRequired,
        isOnlyOne: PropTypes.bool.isRequired,
        isOverConditionalDiscount: PropTypes.bool.isRequired
    };

    static defaultProps = {
        item: {
            id: '',
            product: {},
            productUrl: '',
            name: '',
            imageUrl: '',
            imageLabel: '',
            productQty: 0,
            discountHtml: '',
            reviewsCount: 0,
            reviewSummary: 0,
            isInCart: false,
            isMainProduct: false,
            productPriceHtml: ''
        }
    };

    containerFunctions = {
        handleCheck: this.handleCheck.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            isChecked: true
        };
    }

    handleCheck() {
        const { isChecked } = this.state;
        const { item: { id, product }, handleCheckedProducts } = this.props;

        this.setState(({ isChecked: prevIsChecked }) => ({ isChecked: !prevIsChecked }));
        handleCheckedProducts(id, product, !isChecked);
    }

    containerProps() {
        const {
            item: {
                id,
                name,
                productQty,
                imageUrl,
                imageLabel,
                discountHtml,
                productPriceHtml,
                reviewsCount,
                reviewSummary,
                isMainProduct,
                product,
                product: { url: productUrl }
            },
            isOnlyOne,
            isOverConditionalDiscount
        } = this.props;

        const { isChecked } = this.state;

        return {
            id,
            productUrl,
            name,
            productQty,
            imageUrl,
            imageLabel,
            isChecked,
            discountHtml,
            productPriceHtml,
            reviewsCount,
            reviewSummary,
            isMainProduct,
            isOnlyOne,
            isOverConditionalDiscount,
            product
        };
    }

    render() {
        return (
            <BundlePackItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default BundlePackItemContainer;
