/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductReviewRating from 'Component/ProductReviewRating';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePackItem/Component/BundlePackItemComponent */
export class BundlePackItemComponent extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        productUrl: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        productQty: PropTypes.number.isRequired,
        imageUrl: PropTypes.string.isRequired,
        imageLabel: PropTypes.string.isRequired,
        handleCheck: PropTypes.func.isRequired,
        isChecked: PropTypes.bool.isRequired,
        reviewsCount: PropTypes.number.isRequired,
        reviewSummary: PropTypes.number.isRequired,
        productPriceHtml: PropTypes.string.isRequired,
        discountHtml: PropTypes.string.isRequired,
        isMainProduct: PropTypes.bool.isRequired,
        isOnlyOne: PropTypes.bool.isRequired,
        isOverConditionalDiscount: PropTypes.bool.isRequired
    };

    renderCheckbox() {
        const {
            handleCheck,
            isChecked,
            id,
            isMainProduct
        } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.checkbox }
                  isDisabled={ isMainProduct }
                  attr={ {
                      id,
                      checked: isChecked
                  } }
                  events={ {
                      onChange: handleCheck
                  } }
                />
                    { this.renderDiscount() }
            </>
        );
    }

    renderQty() {
        const { productQty } = this.props;

        if (!productQty) {
            return null;
        }

        return (
            <span>
                { productQty }
            </span>
        );
    }

    renderLabel() {
        const { name } = this.props;

        return (
            <div block="BundlePack" elem="Name">
                <p>
                    { name }
                </p>
            </div>
        );
    }

    renderReviewSummary() {
        const { reviewsCount, reviewSummary } = this.props;

        return (
            <ProductReviewRating
              summary={ reviewSummary }
              count={ reviewsCount }
            />
        );
    }

    renderProductUrl() {
        const {
            productUrl, imageUrl, imageLabel
        } = this.props;

        const isPlaceholder = imageUrl.split('placeholder').length > 1;

        return (
            <Link
              block="BundlePack"
              elem="Link"
              to={ productUrl }
            >
                <Image
                  src={ imageUrl }
                  alt={ imageLabel }
                  className="Image"
                  isPlaceholder={ isPlaceholder }
                />
                { this.renderReviewSummary() }
                { this.renderLabel() }
                { this.renderQty() }
            </Link>
        );
    }

    renderDiscount() {
        const {
            discountHtml,
            isOverConditionalDiscount,
            isChecked,
            isOnlyOne
        } = this.props;

        if (!isChecked || isOnlyOne) {
            return null;
        }

        const sign = discountHtml.replace(/[\d]/g, '');
        const discount = isOverConditionalDiscount
            ? `0${sign}`
            : <Html content={ discountHtml } />;

        return (
            <div block="BundlePack" elem="Discount">
                { discount }
            </div>
        );
    }

    renderProductPrice() {
        const { productPriceHtml } = this.props;

        return (
            <div block="BundlePack" elem="Price">
                <Html content={ productPriceHtml } />
            </div>
        );
    }

    renderPhotoContainer() {
        return (
            <div block="BundlePack" elem="PhotoContainer">
                { this.renderCheckbox() }
                { this.renderProductUrl() }
                { this.renderProductPrice() }
            </div>
        );
    }

    render() {
        return (
            <div block="BundlePackItem">
                { this.renderPhotoContainer() }
            </div>
        );
    }
}

export default BundlePackItemComponent;
