import { connect } from 'react-redux';

import { BRAND } from 'Component/Product/Product.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import fromCache from 'Util/Cache/Cache';
import { ADD_TO_CART } from 'Util/Product';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock
} from 'Util/Product/Extract';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';

/** @namespace Frontend/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Frontend/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Frontend/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    state = {
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
        addToCartTriggeredWithError: false,
        downloadableLinks: [],
        quantity: 1,
        adjustedPrice: {},
        selectedProduct: null,
        parameters: this.props.parameters,
        pickup_location_code: '',
        is_express_pickup: false
    };

    containerProps() {
        const {
            quantity,
            parameters,
            adjustedPrice,
            unselectedOptions,
            addToCartTriggeredWithError,
            pickup_location_code,
            is_express_pickup
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type
        } = activeProduct || {};

        const isProductExist = !this.productCatalogNotExistent();

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options])
        };

        return {
            isWishlistEnabled,
            unselectedOptions,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            isProductExist,
            addToCartTriggeredWithError,
            customAttribute: this.getCustomAttribute(),
            customAttributeNew: this.getCustomAttributeNew(),
            customAttrubuteNewSetAs: this.getCustomAttributeNewSetAs(),
            customAttributeComingSoon: this.getCustomAttributeComingSoon(),
            is_express_pickup,
            pickup_location_code,
            ...output
        };
    }

    containerFunctions = {
        addToCart: this.addToCart.bind(this),

        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),

        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
        setPickup: this.setPickup.bind(this)
    };

    setPickup(pickup_location_code, is_express_pickup) {
        this.setState({ pickup_location_code, is_express_pickup });
    }

    productCatalogNotExistent() {
        const { parameters } = this.state;
        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const { sku } = magentoProduct[0];
        const { sku: skuActiveProduct, variants } = activeProduct;

        if (Object.keys(parameters).length <= 1) {
            return false;
        }

        if (sku === skuActiveProduct && variants !== undefined) {
            return true;
        }

        return false;
    }

    getMagentoProduct() {
        const {
            quantity,
            enteredOptions,
            selectedOptions,
            downloadableLinks,
            parameters
        } = this.state;

        const { product, product: { attributes } } = this.props;

        const configurableOptions = transformParameters(parameters, attributes);
        configurableOptions.sort();

        return magentoProductTransform(
            ADD_TO_CART,
            product,
            quantity,
            enteredOptions,
            [...selectedOptions, ...downloadableLinks, ...configurableOptions]
        );
    }

    async addToCart() {
        this.updateSelectedValues();
        const { showError } = this.props;
        const { is_express_pickup, pickup_location_code } = this.state;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();
        const pickup = { pickup_location_code, is_express_pickup };

        await addProductToCart({ products, cartId, pickup })
            .catch(
                /** @namespace Frontend/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );
    }

    getCustomAttribute() {
        const { product: { sku } } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const { product: { attributes = {} } } = this.props;
        const attribute = attributes[attribute_to_display || BRAND ];

        if (sku && !attribute) {
            return null;
        }

        return attribute;
    }

    getCustomAttributeNew() {
        const {
            product: {
                sku,
                categories,
                attributes = {}
            }
        } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const attributeNew = attributes[attribute_to_display || 'new' ];
        const isComingSoonCategory = categories ? categories.findIndex((e) => e.name === 'Coming soon') : '';

        if ((sku && !attributeNew) || (sku && isComingSoonCategory > -1)) {
            return null;
        }

        return attributeNew;
    }

    getCustomAttributeNewSetAs() {
        const {
            product: {
                sku,
                attributes: { news_from_date: { attribute_value: dateNewFrom } = {} } = {},
                attributes: { news_to_date: { attribute_value: dateNewTo } = {} } = {}
            }
        } = this.props;
        const oggi = new Date();
        const anno = oggi.getFullYear();
        let mese = oggi.getMonth() + 1;
        let giorno = oggi.getDate();
        let ore = oggi.getHours();
        let minuti = oggi.getMinutes();
        let secondi = oggi.getSeconds();

        // Aggiungi uno zero iniziale se il mese, il giorno, le ore, i minuti o i secondi sono inferiori a 10
        mese = mese < 10 ? `0${ mese}` : mese;
        giorno = giorno < 10 ? `0${ giorno}` : giorno;
        ore = ore < 10 ? `0${ ore}` : ore;
        minuti = minuti < 10 ? `0${ minuti}` : minuti;
        secondi = secondi < 10 ? `0${ secondi}` : secondi;
        const dateTime = `${anno}-${mese}-${giorno} ${ore}:${minuti}:${secondi}`;

        if (sku && (dateTime > dateNewFrom && dateTime < dateNewTo)) {
            return true;
        }

        return null;
    }

    getCustomAttributeComingSoon() {
        const {
            product: {
                sku,
                categories,
                attributes = {}
            }
        } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const attributeComingSoon = attributes[attribute_to_display || 'coming_soon' ];
        const isComingSoonCategory = categories ? categories.findIndex((e) => e.name === 'Coming soon') : '';

        if (sku && !attributeComingSoon && isComingSoonCategory > -1) {
            return true;
        }

        if (sku && !attributeComingSoon) {
            return null;
        }

        return attributeComingSoon;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
