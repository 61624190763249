/** extend Sidea */
import PropTypes from 'prop-types';

import CompareIcon from 'Component/CompareIcon';
import Loader from 'Component/Loader';
import {
    ProductCompareButton as SourceProductCompareButton
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.override.style';

/** @namespace Frontend/Component/ProductCompareButton/Component */
export class ProductCompareButton extends SourceProductCompareButton {
    static propTypes = {
        ...SourceProductCompareButton.propTypes,
        goToList: PropTypes.func.isRequired
    };

    render() {
        const {
            isLoading,
            isActive,
            mix,
            goToList
        } = this.props;

        if (isActive) {
            return (
                <div
                  block="ProductCompareButton"
                  mods={ { isActive } }
                  mix={ mix }
                >
                    <button
                      block="ProductCompareButton"
                      elem="Button"
                      onClick={ goToList }
                      aria-label={ __('Compare') }
                    >
                        <CompareIcon isActive={ isActive } />
                        { __('Go to the comparison list') }
                    </button>
                </div>
            );
        }

        return (
            <div
              block="ProductCompareButton"
              mods={ { isActive } }
              mix={ mix }
            >
                <button
                  block="ProductCompareButton"
                  elem="Button"
                  onClick={ this._handleClick }
                  aria-label={ __('Compare') }
                >
                    <CompareIcon isActive={ isActive } />
                    { __('Compare product') }
                    <Loader isLoading={ isLoading } />
                </button>
            </div>
        );
    }
}

export default ProductCompareButton;
