/** override Sidea */

import PropTypes from 'prop-types';

import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace Frontend/Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {
    static propTypes = {
        ...SourceProductAttributeValue.propTypes,
        oneVariant: PropTypes.string.isRequired,
        isProductExist: PropTypes.bool,
        inStock: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...SourceProductAttributeValue.defaultProps,
        isProductExist: true
    };

    loadHandler() {
        const { onClick, attribute, isAvailable } = this.props;

        if (!isAvailable) {
            return;
        }

        onClick(attribute);
    }

    componentDidMount() {
        const { oneVariant } = this.props;

        if (oneVariant == 'true') {
            this.loadHandler();
        }
    }

    clickHandler(e) {
        const { onClick, attribute, isAvailable } = this.props;

        e.preventDefault();
        e.stopPropagation();

        if (!isAvailable) {
            onClick(attribute);
        }

        onClick(attribute);
    }

    renderColorValue(color, label) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = (color == '#fff');

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#dddddd' : color,
            '--option-check-mark-background': isLight ? '#dddddd' : '#fff',
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <data
              block="ProductAttributeValue"
              elem="Color"
              value={ label }
              title={ label }
              style={ style }
            />
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
            isProductExist,
            inStock,
            isSelected
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        const isNotAvailable = !isAvailable;

        const isNotExist = isSelected && !isProductExist;
        const isNotInStock = isSelected && !inStock;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable, isNotExist, isNotInStock } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                { this.renderAttributeByType() }
                </span>
            );
        }

        return (
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable, isNotExist, isNotInStock } }
                  onClick={ this.clickHandler }
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </a>
        );
    }
}

export default ProductAttributeValue;
