/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AmastyAutomaticRelatedProductsProvider } from '../../context/AmastyAutomaticRelatedProducts';

const addAmastyAutomaticRelatedProductsProvider = (member) => [
    (children) => (
         <AmastyAutomaticRelatedProductsProvider>
             { children }
         </AmastyAutomaticRelatedProductsProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAmastyAutomaticRelatedProductsProvider
        }
    }
};
