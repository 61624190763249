/** override sidea */

import PRODUCT_TYPE from 'Component/Product/Product.config';
import SourceProductPrice from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/**
 * Product price
 * @class ProductPrice
 * @namespace Frontend/Component/ProductPrice/Component
 */
export class ProductPrice extends SourceProductPrice {
    priceLabelTypeMap = {
        [PRODUCT_TYPE.simple]: __('Da'),
        [PRODUCT_TYPE.virtual]: __('Da'),
        [PRODUCT_TYPE.bundle]: __('Da'),
        [PRODUCT_TYPE.grouped]: __('Da'),
        [PRODUCT_TYPE.downloadable]: __('Da'),
        [PRODUCT_TYPE.configurable]: __('Da')
    };

    renderTierPrice() {
        const {
            tierPrice,
            price: {
                finalPrice: {
                    valueFormatted = 0
                } = {}
            } = {}
        } = this.props;

        if (!tierPrice || tierPrice === valueFormatted) {
            return null;
        }

        return (
            <p block="ProductPrice" elem="TierPrice">
                { __('Da') }
                <strong>{ ` ${tierPrice}` }</strong>
            </p>
        );
    }

    renderConfigurablePrice() {
        const {
            originalPrice: {
                minFinalPrice: { value: minValue = 0 } = {},
                maxFinalPrice: { value: maxValue = 0 } = {}
            },
            configuration: {
                containsOptions = false
            } = {},
            price: {
                finalPriceExclTax = {},
                finalPrice = {}
            },
            priceType
        } = this.props;

        if (minValue === maxValue && !containsOptions) {
            return this.renderDefaultPrice();
        }

        const { [priceType]: label } = this.priceLabelTypeMap;

        if (minValue === 0) { /* fix temporanea per evitare prodotti non disponibili con prezzo 0. Dal backend arriva un min value = 0 in modo errato. Per una fix migliore dovrebbe essere fatto un lavoro lato BE */
            finalPrice.value = maxValue;
            finalPrice.valueFormatted = `${maxValue.toFixed(2).replace('.', ',') } €`;
            return this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, label);
        }

        return this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, label);
    }
}

export default ProductPrice;
