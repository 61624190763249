/** extend Sidea */

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Frontend/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow, device: { isMobile } } = this.props;

        if (isMobile) {
            return this.renderTogglePlusMinusMobile();
        }

        if (!isMobile && isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return null;
    }

    renderTogglePlusMinusMobile() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <span block="minusIcon">-</span>;
        }

        return <AddIcon />;
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
    }

    renderContent() {
        const {
            children,
            mix,
            device: { isMobile }
        } = this.props;

        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };
        const expand = { isContentExpanded: true };

        if (isMobile) {
            return (
                <div
                  block="ExpandableContent"
                  elem="Content"
                  mods={ mods }
                  mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
                >
                    { children }
                </div>
            );
        }

        return (
            <div
              block="ExpandableContent"
              elem="Content"
              mods={ expand }
              mix={ { ...mix, elem: 'ExpandableContentContent', expand } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { heading, mix, mods } = this.props;

        return (
            <article
              id={ heading }
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}
export default ExpandableContent;
