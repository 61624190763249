/** Util per visualizzazione Popup - Sidea */

import {
    INFO_POPUP_ID,
    MEMBERSHIP_ID,
    SIZE_GUIDE_POPUP_ID,
    SPED_POPUP_ID,
    STORE_POPUP_ID
} from 'Component/Popup/Popup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import getStore from 'Util/Store';

/** @namespace Frontend/Util/Popup/showInfoPopup */
export const showInfoPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(INFO_POPUP_ID, { title: '' }));
};

/** @namespace Frontend/Util/Popup/showSpedInfoPopup */
export const showSpedInfoPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(SPED_POPUP_ID, { title: '' }));
};

/** @namespace Frontend/Util/Popup/showStorePickupPopup */
export const showStorePickupPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(STORE_POPUP_ID, { title: '' }));
};

/** @namespace Frontend/Util/Popup/showSizeGuidePopup */
export const showSizeGuidePopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(SIZE_GUIDE_POPUP_ID, { title: '' }));
};

/** @namespace Frontend/Util/Popup/showMembershipPopup */
export const showMembershipPopup = () => {
    const store = getStore();
    const { dispatch } = store;

    dispatch(showPopup(MEMBERSHIP_ID, { title: '' }));
};
