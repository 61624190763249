/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/ClickMutation/Query */
export class ClickMutationQuery {
    getQuery(blockId, productId) {
        return new Field('amastyClickAnalytic')
            .addArgument('blockId', 'Int!', blockId)
            .addArgument('productId', 'Int!', productId)
            .addField('message');
    }
}

export default new ClickMutationQuery();
