/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getSortedItems } from 'Util/Menu';

import { AMASTY_TOP_MENU_FIRST } from '../../component/PackList/PackList.config';

export const addBundlePacksToMenu = (args, callback, instance) => {
    const {
        menu,
        amastyARPConfig: {
            isEnabled,
            isTopMenuEnabled,
            cmsPageUrlKey,
            menuLabel,
            topMenuOrder
        } = {}
    } = instance.props;

    if (!isEnabled || !isTopMenuEnabled) {
        return callback(...args);
    }

    const categoryArray = Object.values(menu);

    if (!categoryArray.length) {
        return null;
    }

    const [{ children, title: mainCategoriesTitle }] = categoryArray;

    if (topMenuOrder !== AMASTY_TOP_MENU_FIRST) {
        // vvv Checking for last position of previous menu item to pass our BundlePacks link next to it
        const CATEGORY_INDEX = +Object.keys(children)
            .map((index) => index)[Object.keys(children).map((index) => index).length - 1] + 1;

        const ITEM_ID = CATEGORY_INDEX.toString();
        const pos = Object.values(children).map((value) => {
            const { position } = value;
            return position;
        });

        const NEXT_POS = 10;
        const nextPosition = pos[pos.length - 1] + NEXT_POS;
        const newItem = {
            25: {
                children: {},
                icon: '',
                is_active: true,
                item_class: '',
                item_id: ITEM_ID,
                parent_id: 1,
                position: nextPosition,
                title: menuLabel,
                url: {
                    pathname: `/${cmsPageUrlKey}`,
                    search: '',
                    state: {
                        category: CATEGORY_INDEX
                    }
                }
            }
        };

        const newChildren = { ...children, ...newItem };
        const childrenArray = getSortedItems(Object.values(newChildren));
        return (
            <>
                <div block="Menu" elem="MainCategories">
                    { instance.renderAdditionalInformation(true) }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(instance.renderFirstLevel.bind(instance)) }
                    </ul>
                </div>
                { instance.renderSubMenuDesktop(children) }
            </>
        );
    }

    const newItem = {
        0: {
            children: {},
            icon: '',
            is_active: true,
            item_class: '',
            item_id: '0',
            parent_id: 1,
            position: 1,
            title: menuLabel,
            url: {
                pathname: `/${cmsPageUrlKey}`,
                search: '',
                state: {
                    category: 0
                }
            }
        }
    };

    const newChildren = { ...children, ...newItem };
    const childrenArray = getSortedItems(Object.values(newChildren));
    return (
        <>
            <div block="Menu" elem="MainCategories">
                { instance.renderAdditionalInformation(true) }
                <ul
                  block="Menu"
                  elem="ItemList"
                  mods={ { type: 'main' } }
                  aria-label={ mainCategoriesTitle }
                >
                    { childrenArray.map(instance.renderFirstLevel.bind(instance)) }
                </ul>
            </div>
            { instance.renderSubMenuDesktop(children) }
        </>
    );
};

export default {
    'Component/Menu/Component': {
        'member-function': {
            renderTopLevel: addBundlePacksToMenu
        }
    }
};
