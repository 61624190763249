/** override Sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { noopFn } from 'Util/Common';

import './ProductTab.override.style';

/** @namespace Frontend/Component/ProductTab/Component */
export class ProductTab extends PureComponent {
    static propTypes = {
        tabName: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        isActive: PropTypes.bool,
        anchorName: PropTypes.string.isRequired
    };

    static defaultProps = {
        onClick: noopFn,
        isActive: false
    };

    onClick = this.onClick.bind(this);

    onClick() {
        const { onClick, tabName } = this.props;
        onClick(tabName);
    }

    render() {
        const { tabName, isActive, anchorName } = this.props;

        return (
            <li
              id={ anchorName }
              block="ProductTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'ProductTab', elem: 'Button' } }
                  onClick={ this.onClick }
                >
                    { tabName }
                </button>
            </li>
        );
    }
}

export default ProductTab;
