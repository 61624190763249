/** Component Sidea - Popup selezione pickup store in pagina dettaglio prodotto */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { noopFn } from 'Util/Common';

import StorePickupPopup from './ProductCollectShippingMethods.component';

/** @namespace Frontend/Component/ProductCollectShippingMethods/Container */
export class ProductCollectShippingMethodsContainer extends PureComponent {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        inStock: PropTypes.bool.isRequired,
        setPickup: PropTypes.func
    };

    static defaultProps = {
        setPickup: noopFn
    };

    state = {
        singleStore: '',
        selectedStoreLocationCode: '',
        selectedStoreIsExpress: false
    };

    containerFunctions = {
        handleSelectStore: this.handleSelectStore.bind(this),
        handleSelectionReset: this.handleSelectionReset.bind(this)
    };

    componentDidMount() {
        const { setPickup } = this.props;
        const { selectedStoreIsExpress, selectedStoreLocationCode } = this.state;

        setPickup(selectedStoreLocationCode, selectedStoreIsExpress);
    }

    componentDidUpdate(prevState) {
        const { setPickup } = this.props;
        const { selectedStoreIsExpress, selectedStoreLocationCode } = this.state;
        const { selectedStoreIsExpress: prevSelectedStoreIsExpress, selectedStoreLocationCode: prevSelectedStoreLocationCode } = prevState;

        if (selectedStoreIsExpress !== prevSelectedStoreIsExpress || selectedStoreLocationCode !== prevSelectedStoreLocationCode) {
            setPickup(selectedStoreLocationCode, selectedStoreIsExpress);
        }
    }

    containerProps() {
        const {
            sku,
            inStock
        } = this.props;

        const {
            singleStore,
            selectedStoreLocationCode,
            selectedStoreIsExpress
        } = this.state;

        return {
            singleStore,
            selectedStoreLocationCode,
            selectedStoreIsExpress,
            sku,
            inStock
        };
    }

    handleSelectionReset() {
        this.setState({
            singleStore: '',
            selectedStoreLocationCode: '',
            selectedStoreIsExpress: false
        });
    }

    handleSelectStore(e) {
        let isExpress = e.target.getAttribute('data-isExpress');
        if (isExpress === 'true') {
            isExpress = true;
        } else {
            isExpress = false;
        }
        this.setState({
            singleStore: e.target.id,
            selectedStoreLocationCode: e.target.getAttribute('data-locationCode'),
            selectedStoreIsExpress: isExpress
        });
    }

    render() {
        return (
            <StorePickupPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductCollectShippingMethodsContainer;
