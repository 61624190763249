/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { ProductType } from 'Type/ProductList.type';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';

import { ItemType } from '../../type/AmastyAutomaticRelatedProducts.type';
import ProductsPopUp from './ProductsPopUp.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductsPopUp/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductsPopUp/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    )
});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductsPopUp/Container */
export class ProductsPopUpContainer extends PureComponent {
    static propTypes = {
        products: PropTypes.arrayOf(ProductType).isRequired,
        items: PropTypes.arrayOf(ItemType).isRequired,
        showNotification: PropTypes.func.isRequired,
        fallbackAddToCart: PropTypes.func.isRequired
    };

    containerFunctions = {
        setActiveProducts: this.setActiveProducts.bind(this),
        setActiveParameters: this.setActiveParameters.bind(this),
        addToCart: this.addToCart.bind(this),
        validateParams: this.validateParams.bind(this),
        setActiveQuantities: this.setActiveQuantities.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            activeProduct: {},
            activeProducts: [],
            activeParameters: {},
            activeQuantities: {},
            isAdding: false
        };
    }

    addToCart() {
        const { showNotification } = this.props;
        if (!this.validateParams()) {
            showNotification('error', __('Choose all options'));
            return;
        }

        this.addToCartAction();
    }

    async addToCartAction() {
        const { activeProducts, activeParameters } = this.state;
        const { fallbackAddToCart, products: mainProducts } = this.props;

        this.setState({ isAdding: true });
        const products = activeProducts.map((product) => {
            const mainProduct = mainProducts.find((item) => {
                const { variants } = item;
                const { id } = product;
                const inVariants = variants.some((variant) => variant.product.id === id);
                return inVariants;
            });
            const { id, attributes } = product;
            const selectedQuantity = this.getQuantity(id);
            const parameters = activeParameters[mainProduct.id];
            const configurableOptions = transformParameters(parameters, attributes);

            return magentoProductTransform(
                ADD_TO_CART, mainProduct, selectedQuantity, [], Array.from(configurableOptions)
            )[0];
        });

        await fallbackAddToCart({ products });
        this.setState({ isAdding: false });

        history.back();
    }

    getQuantity(id) {
        const { activeQuantities } = this.state;
        const array = Object.values(activeQuantities);
        const hasQuantity = array.filter(({ variants }) => variants.some((item) => item.id === id));

        const { quantity } = hasQuantity[0] || {};

        return quantity || 1;
    }

    setActiveProducts(product) {
        if (!product) {
            return;
        }

        const { activeProducts } = this.state;

        const isProductPresent = activeProducts.find((item) => {
            const { uid } = item;
            const { uid: activeUid } = product;
            return uid === activeUid;
        });

        if (!isProductPresent) {
            activeProducts.push(product);
            this.setState({ activeProducts });
        }
    }

    validateParams() {
        const { products } = this.props;
        const { activeParameters } = this.state;

        if (Object.keys(activeParameters).length !== products.length) {
            return false;
        }

        const hasWrongParams = Object.values(activeParameters).some((item) => !item);

        if (hasWrongParams) {
            return false;
        }

        const hasNotApppliedOptions = products.map((product) => {
            const { id, configurable_options } = product;
            const attr = activeParameters[id];
            if (Object.keys(attr).length !== configurable_options.length) {
                return product;
            }

            return null;
        }).filter((item) => item);

        if (hasNotApppliedOptions.length > 0) {
            return false;
        }

        return true;
    }

    setActiveParameters(parameters, product) {
        const { activeParameters } = this.state;
        const { id } = product;

        if (Object.keys(parameters).length === 0) {
            const newObject = { [id]: null };
            const newParameters = { ...activeParameters, ...newObject };
            this.setState({ activeParameters: newParameters });
            return;
        }

        const newObject = {
            [id]: parameters
        };

        const newParameters = { ...activeParameters, ...newObject };
        this.setState({ activeParameters: newParameters });

        this.validateParams();
    }

    setActiveQuantities(quantity, product) {
        const { activeQuantities } = this.state;
        const { id, variants } = product;

        const newObject = {
            [id]: { quantity, variants }
        };

        const newQuantities = { ...activeQuantities, ...newObject };

        this.setState({ activeQuantities: newQuantities });
    }

    containerProps() {
        const { items, products } = this.props;
        const { activeProduct, isAdding } = this.state;

        return {
            products,
            activeProduct,
            items,
            isAdding
        };
    }

    render() {
        return (
            <ProductsPopUp
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPopUpContainer);
