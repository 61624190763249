/* eslint-disable import/prefer-default-export */

/** Override Sidea */

export const HOME_TAB = 'HOME_TAB';
export const MENU_TAB = 'MENU_TAB';
export const ACCOUNT_TAB = 'ACCOUNT_TAB';
export const CART_TAB = 'CART_TAB';
export const CHECKOUT_TAB = 'CHECKOUT_TAB';
export const SEARCH_TAB = 'SEARCH_TAB';
