/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EqualsIcon.style';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/EqualsIcon/Component/EqualsIconComponent */
export class EqualsIconComponent extends PureComponent {
     static propTypes = {
         isPrimary: PropTypes.bool
     };

     static defaultProps = {
         isPrimary: false
     };

     render() {
         const { isPrimary } = this.props;

         return (
             <svg
               block="EqualsIcon"
               mods={ { isPrimary } }
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
                 <path
                   d="M8,18h16v4H8V18z M8,10v4h16v-4H8z"
                 />
             </svg>
         );
     }
}

export default EqualsIconComponent;
