/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ItemType } from '../../type/AmastyAutomaticRelatedProducts.type';
import ProductBundlePack from '../ProductBundlePack';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePacks/Component/BundlePacksComponent */
export class BundlePacksComponent extends PureComponent {
    static propTypes = {
        bundlePacks: PropTypes.arrayOf(ItemType).isRequired
    };

    renderPack = (item, idx) => (
        <ProductBundlePack
          key={ item.packId }
          cartBundle={ item }
          index={ idx }
        />
    );

    renderPacks() {
        const { bundlePacks } = this.props;

        if (!bundlePacks) {
            return null;
        }

        return bundlePacks.map(this.renderPack);
    }

    render() {
        return (
            <div block="BundlePacks">
                { this.renderPacks() }
            </div>
        );
    }
}

export default BundlePacksComponent;
