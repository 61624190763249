import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { showPopup } from 'Store/Popup/Popup.action';
import { MixType } from 'Type/Common.type';
import { LayoutType } from 'Type/Layout.type';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Frontend/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Frontend/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

/** @namespace Frontend/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        product: ProductType,
        quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(PropTypes.number)]),
        price: PropTypes.objectOf(PropTypes.string),
        priceType: PropTypes.number.isRequired,
        tierPrices: PropTypes.objectOf(PropTypes.string).isRequired,
        cartId: PropTypes.string,
        showNotification: PropTypes.func.isRequired,
        addToCart: PropTypes.func,
        fallbackAddToCart: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        withLink: PropTypes.bool,
        showPopup: PropTypes.func.isRequired,

        isIconEnabled: PropTypes.bool,
        mix: MixType,
        layout: LayoutType
    };

    static defaultProps = {
        quantity: 1,
        cartId: '',
        mix: {},
        layout: GRID_LAYOUT,
        isIconEnabled: true,
        isDisabled: false,
        addToCart: null,
        updateSelectedValues: null,
        withLink: false,
        product: {},
        price: {}
    };

    containerProps() {
        const {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            product,
            quantity,
            price,
            priceType,
            tierPrices
        } = this.props;

        const {
            isAdding
        } = this.state;

        return {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            isAdding,
            product,
            quantity,
            price,
            priceType,
            tierPrices
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
