/** Component Sidea - Popup informazioni spedizione in pagina dettaglio prodotto */
import { Component } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';
import { SPED_POPUP_ID } from 'Component/Popup/Popup.config';

import './InfoSpedizionePopup.style';

/** @namespace Frontend/Component/InfoSpedizionePopup/Component */
export class InfoSpedizionePopup extends Component {
    render() {
        return (
            <Popup
              id={ SPED_POPUP_ID }
              mix={ { block: 'Infosped', elem: 'Popup' } }
            >
                <CmsBlock identifier="popup-info-spedizione" />
            </Popup>
        );
    }
}

export default InfoSpedizionePopup;
