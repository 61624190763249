import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCompareButtonContainer as SourceProductCompareButtonContainer
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.container';
import history from 'Util/History';

/** @namespace Frontend/Component/ProductCompareButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Frontend/Component/ProductCompareButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Frontend/Component/ProductCompareButton/Container */
export class ProductCompareButtonContainer extends SourceProductCompareButtonContainer {
    containerFunctions = {
        handleClick: this.handleClick.bind(this),
        goToList: this.goToList.bind(this)
    };

    async handleClick(e) {
        const {
            productId,
            addProductToCompare,
            removeComparedProduct
        } = this.props;

        const { isLoading } = this.state;

        if (isLoading) {
            return;
        }

        e.preventDefault();

        this.setState({ isLoading: true });

        if (this.isActive()) {
            await removeComparedProduct(productId);
        } else {
            await addProductToCompare(productId);
        }

        this.setState({ isLoading: false });
    }

    goToList() {
        history.push('/compare');
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCompareButtonContainer);
