/** Override Sidea */

import { Field } from 'Util/Query';

/** @namespace Frontend/Query/ProductAlerts/Query */
export class ProductAlertsQuery {
    getProductAlertSubscribeMutation(productId, type, email) {
        return new Field('productAlertSubscribe')
            .addArgument('productId', 'ID!', productId)
            .addArgument('type', 'String!', type)
            .addArgument('email', 'String', email);
    }
}

export default new ProductAlertsQuery();
