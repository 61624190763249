/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/CartMessage/Query/CartMessageQuery */
export class CartMessageQuery {
    getQuery() {
        return new Field('cartMessage')
            .addFieldList(this.getCartMessageFields());
    }

    getCartMessageFields() {
        return [
            'packId',
            'message'
        ];
    }
}

export default new CartMessageQuery();
