/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';
/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/BundlePacks/Query/BundlePacksQuery */
export class BundlePacksQuery {
    getQuery(productId) {
        return new Field('bundles')
            .addArgument('productId', 'Int!', productId)
            .addFieldList(this.getBundlesFields());
    }

    getBundlesFields() {
        return [
            'packId',
            'packKey',
            'isWideLayout',
            'isMultiRowLayout',
            'itemClass',
            'itemsCount',
            'multiRowClass',
            'discount',
            'conditionalDiscount',
            'finalPrice',
            'title',
            'childIds',
            'totalPages',
            this.getItems()
        ];
    }

    getItems() {
        return new Field('items')
            .addFieldList(this.getItemsFields());
    }

    getItemsFields() {
        return [
            'id',
            this.getProduct(),
            'productQty',
            'productUrl',
            'imageUrl',
            'imageLabel',
            'discountHtml',
            'name',
            'reviewsCount',
            'reviewSummary',
            'isInCart',
            'isMainProduct',
            this.getMediaGallery(),
            'productPriceHtml'
        ];
    }

    getMediaGallery() {
        return [
            'id',
            'file'
        ];
    }

    getProduct() {
        return new Field('product')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }
}

export default new BundlePacksQuery();
