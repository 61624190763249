/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { lazy } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import ProductPageComponent from 'Route/ProductPage/ProductPage.component';

import ProductOptions from '../ProductOptions';

import './BundleProductsPage.style';

export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'Component/ProductGallery'
));

export const ProductAttributes = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-attributes" */
    'Component/ProductAttributes'
));

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundleProductsPage/Component/BundleProductsPageComponent */
export class BundleProductsPageComponent extends ProductPageComponent {
    static propTypes = {
        ...ProductPageComponent.PropTypes,
        setActiveParameters: PropTypes.func.isRequired,
        setActiveQuantities: PropTypes.func.isRequired
    };

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
            setActiveParameters,
            setActiveQuantities
        } = this.props;

        return (
            <>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                <ProductOptions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }
                  setActiveParameters={ setActiveParameters }
                  setActiveQuantities={ setActiveQuantities }
                />
            </>
        );
    }

    render() {
        return (
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                    </ContentWrapper>
                </main>
        );
    }
}

export default BundleProductsPageComponent;
