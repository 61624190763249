import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Frontend/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Frontend/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Frontend/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    __construct(props) {
        super.__construct(props);
        this.state = {
            smallImage: ''
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        showSelectOptionsNotification: this.showSelectOptionsNotification.bind(this),
        handleClickColor: this.handleClickColor.bind(this)
    };

    containerProps() {
        const { smallImage } = this.state;

        return {
            ...super.containerProps(),
            smallImage
        };
    }

    handleClickColor(event) {
        const { product: { variants } } = this.props;
        const value = event.target.getAttribute('value');
        const IMAGE_URL = '/media/catalog/product';

        const result = variants.map((variant) => (variant.attributes.color.attribute_value === value ? variant : null)).find((variant) => variant !== null);
        const small_image = result.attributes.small_image.attribute_value;
        const urlImage = appendWithStoreCode(`${ IMAGE_URL }${ small_image }`);

        this.setState({ smallImage: urlImage });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
