/** Component Sidea - Popup selezione pickup store in pagina dettaglio prodotto */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import StoreInPickUpQuery from 'Query/StoreInPickUp.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { clearPickUpStore, setPickUpStore } from 'Store/StoreInPickUp/StoreInPickUp.action';
import { StoreType } from 'Type/Checkout.type';
import { checkIfStoreIncluded } from 'Util/Address';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import StorePickupPopup from './StorePickupPopup.component';

/** @namespace Frontend/Component/StorePickupPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    setPickUpStore: (store) => dispatch(setPickUpStore(store)),
    clearPickUpStore: () => dispatch(clearPickUpStore())
});

/** @namespace Frontend/Component/StorePickupPopup/Container/mapStateToProps  */
export const mapStateToProps = (state) => ({
    defaultCountry: state.ConfigReducer.default_country,
    selectedStore: state.StoreInPickUpReducer.store
});

/** @namespace Frontend/Component/StorePickupPopup/Container */
export class StorePickupPopupContainer extends PureComponent {
    static propTypes = {
        countryId: PropTypes.string.isRequired,
        onStoreSelect: PropTypes.func.isRequired,
        defaultCountry: PropTypes.string.isRequired,
        clearPickUpStore: PropTypes.func.isRequired,
        setPickUpStore: PropTypes.func.isRequired,
        selectedStore: StoreType,
        handleSelectStore: PropTypes.func.isRequired,
        handleSelectionReset: PropTypes.func.isRequired,
        singleStore: PropTypes.string,
        sku: PropTypes.string.isRequired
    };

    static defaultProps = {
        selectedStore: null,
        singleStore: ''
    };

    state = {
        stores: [],
        storeSearchCriteria: '',
        isLoading: true
    };

    containerFunctions = {
        handleStoresSearch: this.handleStoresSearch.bind(this),
        setStoreSearchCriteria: this.setStoreSearchCriteria.bind(this)
    };

    __construct(props) {
        const {
            countryId,
            defaultCountry
        } = props;

        this.state = {
            selectedCountryId: countryId || defaultCountry
        };
    }

    componentDidMount() {
        this.handleStoresSearch();
    }

    componentDidUpdate(prevProps, prevState) {
        const STORES_SEARCH_TIMEOUT = 0;
        const { sku } = this.props;
        const { sku: prevSku } = prevProps;
        const {
            storeSearchCriteria: prevStoreSearchCriteria
        } = prevState;
        const { storeSearchCriteria } = this.state;

        if (storeSearchCriteria !== prevStoreSearchCriteria) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.setIsLoading();
            this.timeout = setTimeout(() => {
                this.timeout = null;
                this.handleStoresSearch();
            }, STORES_SEARCH_TIMEOUT);
        }

        if (sku !== prevSku) {
            this.handleStoresSearch();
        }
    }

    containerProps() {
        const {
            sku,
            singleStore,
            handleSelectStore,
            handleSelectionReset
        } = this.props;

        const {
            isLoading,
            selectedCountryId,
            stores,
            selectedStore,
            storeSearchCriteria
        } = this.state;

        return {
            isLoading,
            selectedCountryId,
            stores,
            selectedStore,
            sku,
            singleStore,
            handleSelectStore,
            handleSelectionReset,
            storeSearchCriteria
        };
    }

    clearStores() {
        this.setState({ stores: [] });
    }

    setIsLoading() {
        this.setState({ isLoading: true });
    }

    setStoreSearchCriteria(searchCriteria) {
        this.setState({ storeSearchCriteria: searchCriteria.target.value });
    }

    async handleStoresSearch() {
        const {
            selectedStore,
            clearPickUpStore,
            sku
        } = this.props;
        const { storeSearchCriteria, selectedCountryId } = this.state;

        const skuarray = [{ sku }];

        try {
            const {
                getStores: {
                    stores
                } = {}
            } = await fetchQuery(StoreInPickUpQuery.getStores(selectedCountryId, storeSearchCriteria, skuarray));

            if (stores) {
                this.setState({ stores });
            }

            if (!checkIfStoreIncluded(stores, selectedStore)) {
                clearPickUpStore();
            }

            this.setState({ isLoading: false });
        } catch (e) {
            this.setState({ stores: [] });
            showNotification('error', getErrorMessage(e));
        }
    }

    render() {
        return (
            <StorePickupPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePickupPopupContainer);
