/* Override Sidea */

import Html from 'Component/Html';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import ProductAlerts from 'Component/ProductAlerts';
import ProductCollectShippingMethods from 'Component/ProductCollectShippingMethods';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductActions as SourceProductActions
} from 'SourceComponent/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';

import './ProductActions.style';

/** @namespace Frontend/Component/ProductActions/Component */
export class ProductActions extends SourceProductActions {
    renderDescriptionAnchorLink() {
        return (
            <a href="#ShippingMethods" onClick="window.location.href = '#ShippingMethods'">{ __('Keep reading') }</a>
        );
    }

    renderShortDescriptionContent() {
        const { product: { short_description } } = this.props;
        const { html } = short_description || {};

        const htmlWithItemProp = `<span itemProp="description" class="ProductActions-ShortDescription">${ html }</span>`;

        return (
             <div block="ProductActions" elem="ShortDescription">
                 { html ? <Html content={ htmlWithItemProp } /> : <p><TextPlaceholder length="long" /></p> }
                 { html ? this.renderDescriptionAnchorLink() : '' }
             </div>
        );
    }

    renderReviewSection() {
        const { areReviewsEnabled } = this.props;

        if (!areReviewsEnabled) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="Reviews"
            >
                { this.renderRatingSummary() }
                { this.renderReviewButton() }
            </div>
        );
    }

    renderShippingMethods() {
        /* setPickup è una funzione che si trova in frontend/src/Component/Product/ProductContainer */
        const {
            getActiveProduct,
            setPickup,
            inStock
        } = this.props;
        const { sku } = getActiveProduct();

        return <ProductCollectShippingMethods sku={ sku } setPickup={ setPickup } inStock={ inStock } />;
    }

    renderSkuAndStock() {
        const {
            getActiveProduct,
            showOnlyIfLoaded
        } = this.props;

        const { sku } = getActiveProduct();

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'sku' } }
              aria-label="Product SKU and availability"
            >
                { showOnlyIfLoaded(
                    sku,
                    (
                        <>
                            { this.renderSku() }
                        </>
                    ),
                    <TextPlaceholder />
                ) }
            </section>
        );
    }

    renderProductAlertWishlistButton() {
        const { inStock } = this.props;

        if (inStock) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper outOfStock"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                <div block="ProductActions" elem="ActionButtons">
                    { this.renderWishlistButton() }
                </div>
            </div>
        );
    }

    renderProductAlerts() {
        const {
            areDetailsLoaded,
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            getActiveProduct,
            inStock
        } = this.props;

        if (
            (!isInStockAlertEnabled && !isPriceAlertEnabled)
            || !areDetailsLoaded
        ) {
            return null;
        }

        const { id } = getActiveProduct();
        const isPriceAlert = false;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'alerts' } }
            >
                <ProductAlerts
                  productId={ id }
                  stockStatus={ inStock ? STOCK_TYPE.IN_STOCK : STOCK_TYPE.OUT_OF_STOCK }
                  isPriceAlert={ isPriceAlert }
                />
                { this.renderProductAlertWishlistButton() }
            </section>
        );
    }

    renderProductAlertsPrice() {
        const {
            areDetailsLoaded,
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            getActiveProduct,
            inStock
        } = this.props;

        if (
            (!isInStockAlertEnabled && !isPriceAlertEnabled)
            || !areDetailsLoaded
        ) {
            return null;
        }

        const { id } = getActiveProduct();
        const isPriceAlert = true;

        return (
                <ProductAlerts
                  productId={ id }
                  stockStatus={ inStock ? STOCK_TYPE.IN_STOCK : STOCK_TYPE.OUT_OF_STOCK }
                  isPriceAlert={ isPriceAlert }
                />
        );
    }

    renderPriceMobile() {
        const { device: { isMobile } = {} } = this.props;

        if (isMobile) {
            return this.renderPriceWithGlobalSchema();
        }

        return null;
    }

    renderAddToCartAction() {
        const { device: { isMobile } = {} } = this.props;

        if (isMobile) {
            return (
                <div block="ProductActions" elem="AddToCartFixed">
                    { this.renderAddToCartButton() }
                </div>
            );
        }

        return this.renderAddToCartButton();
    }

    renderAddToCartActionBlock() {
        const { inStock, isProductExist } = this.props;

        if (!isProductExist) {
            return (
                <p block="ProductActions" elem="ProductNotExist">
                    { __('The selected product cannot currently be purchased online.') }
                </p>
            );
        }

        if (!inStock) {
            return this.renderAddToCartButton();
        }

        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderQuantityChanger() }
                { this.renderStock(false) }
                { this.renderPriceMobile() }
                { this.renderAddToCartAction() }
                <div block="ProductActions" elem="ActionButtons">
                    { this.renderWishlistButton() }
                </div>
                { this.renderCompareButton() }
            </div>
        );
    }

    renderDesktop() {
        return (
            <>
                { this.renderLabelNew() }
                { this.renderLabelSale() }
                { this.renderLabelComingSoon() }
                { this.renderBrand(true) }
                { this.renderName() }
                { this.renderReviewSection() }
                { this.renderSkuAndStock() }
                { this.renderShortDescription() }
                { this.renderPriceWithGlobalSchema() }
                { this.renderProductAlertsPrice() }
                { this.renderConfigurableOptions() }
                { this.renderCustomAndBundleOptions() }
                { this.renderGroupedOptions() }
                { this.renderDownloadableSamples() }
                { this.renderDownloadableLinks() }
                { this.renderTierPrices() }
                { this.renderProductAlerts() }
                { this.renderAddToCartActionBlock() }
                { this.renderShippingMethods() }
            </>
        );
    }

    renderMobile() {
        return (
            <>
                { this.renderLabelNew() }
                { this.renderLabelSale() }
                { this.renderLabelComingSoon() }
                { this.renderBrand(true) }
                { this.renderName() }
                { this.renderReviewSection() }
                { this.renderSkuAndStock() }
                { this.renderShortDescription() }
                { this.renderConfigurableOptions() }
                { this.renderCustomAndBundleOptions() }
                { this.renderGroupedOptions() }
                { this.renderDownloadableSamples() }
                { this.renderDownloadableLinks() }
                { this.renderTierPrices() }
                { this.renderProductAlerts() }
                { this.renderAddToCartActionBlock() }
                { this.renderShippingMethods() }
            </>
        );
    }
}

export default ProductActions;
