/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AmastyAutomaticRelatedProductsContext } from '../../context/AmastyAutomaticRelatedProducts';
import BundlePacks from './BundlePacks.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePacks/Container/BundlePacksContainer */
export class BundlePacksContainer extends PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired
    };

    static contextType = AmastyAutomaticRelatedProductsContext;

    containerFunctions = {};

    containerProps() {
        const { bundlePacks } = this.context;

        return {
            bundlePacks
        };
    }

    render() {
        return (
            <BundlePacks
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default BundlePacksContainer;
