/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    AMOUNT_OF_PLACEHOLDERS,
    IMAGE_TYPE,
    THUMBNAIL_KEY
} from 'Component/ProductGallery/ProductGallery.config';

export const fixedGettingImageForCartBundle = (args, callback, instance) => {
    const {
        areDetailsLoaded,
        product: {
            media_gallery_entries: mediaGallery = [],
            thumbnail: { url: thumbnailUrl } = {},
            image: { url: imageTypeUrl } = {},
            name
        }
    } = instance.props;

    const url = thumbnailUrl || imageTypeUrl;

    if (mediaGallery.length) {
        return mediaGallery
            .filter(({ disabled }) => !disabled)
            .sort((a, b) => a.position - b.position);
    }

    if (!url) {
        return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
    }

    const placeholders = !areDetailsLoaded
        ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

    return [
        {
            thumbnail: { url },
            base: { url },
            id: THUMBNAIL_KEY,
            label: name,
            media_type: IMAGE_TYPE
        },
        ...placeholders
    ];
};

export default {
    'Component/ProductGallery/Container': {
        'member-function': {
            getGalleryPictures: fixedGettingImageForCartBundle
        }
    }
};
