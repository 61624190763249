/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import Pagination from 'Component/Pagination';

import ProductBundlePack from '../ProductBundlePack';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/PackList/Component/PackListComponent */
export class PackListComponent extends PureComponent {
    static propTypes = {
        packList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        packListTotalPages: PropTypes.number.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    renderPack = (pack, idx) => (
        <ProductBundlePack
          key={ pack.packId }
          cartBundle={ pack }
          index={ idx }
        />
    );

    renderPackList() {
        const { packList } = this.props;

        return packList.map(this.renderPack);
    }

    renderLoader() {
        const { isLoading } = this.props;

        return <Loader isLoading={ isLoading } />;
    }

    renderPagination() {
        const { packListTotalPages } = this.props;

        return <Pagination totalPages={ packListTotalPages } />;
    }

    render() {
        return (
            <div block="PackList">
                { this.renderLoader() }
                { this.renderPackList() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default PackListComponent;
