/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { formatPrice } from 'Util/Price';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import { BundleType } from '../../type/AmastyAutomaticRelatedProducts.type';
import {
    getFinalPriceAndDiscount,
    getFloatPrice,
    getNewProductIds,
    getNewProducts,
    showNewPopup
} from '../../util/AmastyAutomaticRelatedProducts';
import BundlePack from './BundlePack.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePack/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartBundle: state.CartReducer.cartTotals?.cartBundle,
    cartMessage: state.CartReducer.cartTotals?.cartMessage,
    currency: state.CartReducer?.cartTotals?.prices?.quote_currency_code
});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePack/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    )
});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/BundlePack/Container/BundlePackContainer */
export class BundlePackContainer extends PureComponent {
    static propTypes = {
        cartBundle: BundleType,
        cartMessage: PropTypes.shape({
            packId: PropTypes.number
        }),
        fallbackAddToCart: PropTypes.func.isRequired,
        currency: PropTypes.string
    };

    static defaultProps = {
        cartBundle: {
            title: '',
            packKey: '',
            items: [],
            itemClass: '',
            discount: '',
            conditionalDiscount: 0,
            finalPrice: '',
            childIds: '',
            multiRowClass: ''
        },
        cartMessage: {
            packId: 0
        },
        currency: ''
    };

    containerFunctions = {
        handleCheckedProducts: this.handleCheckedProducts.bind(this),
        handleButtonClick: this.handleButtonClick.bind(this)
    };

    componentDidUpdate(prevProps, prevState) {
        const { productIds: prevProductIds } = prevState;
        const { productIds } = this.state;
        const { cartBundle: { childIds: prevChildIds, packId: prevPackId } } = prevProps;
        const { cartBundle: { childIds, packId } } = this.props;

        if (packId !== prevPackId || childIds !== prevChildIds) {
            this.getProductIds();
            this.getProducts();
            this.getNewFinalPriceAndDiscount();
        }

        if (productIds !== prevProductIds) {
            this.getNewFinalPriceAndDiscount();
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            products: [],
            productIds: '',
            newDiscount: '',
            newFinalPrice: '',
            isAdding: false,
            isOnlyOne: false,
            isOverConditionalDiscount: false
        };

        this.getProductIds();
        this.getProducts();
    }

    getProductIds() {
        const { cartBundle: { childIds, items } = {} } = this.props;

        if (!childIds) {
            return '';
        }

        const newProductIds = getNewProductIds(childIds, 0, false, items);

        this.setState({ productIds: newProductIds });

        return newProductIds;
    }

    getProducts() {
        const { cartBundle: { items } } = this.props;

        const newProducts = items.map((item) => {
            const { product } = item;

            return product;
        });

        this.setState({ products: newProducts });

        return newProducts;
    }

    async handleButtonClick() {
        const { products } = this.state;
        const { fallbackAddToCart } = this.props;

        if (products?.length) {
            return;
        }

        this.setState({ isAdding: true });

        const hasConfigurableOptions = products.filter((product) => {
            const { configurable_options } = product;

            if (configurable_options && configurable_options.length > 0) {
                return product;
            }

            return null;
        });

        if (hasConfigurableOptions.length > 0) {
            this.setState({ isAdding: false });
            showNewPopup();
            return;
        }

        const compatibleProducts = products.map((product) => magentoProductTransform(ADD_TO_CART, product, 1)[0]);

        await fallbackAddToCart({ products: compatibleProducts });
        this.setState({ isAdding: false });
    }

    handleCheckedProducts(id, product, isChecked) {
        const { productIds, products } = this.state;
        const { cartBundle: { items } } = this.props;
        const newProductIds = getNewProductIds(productIds || this.getProductIds(), id, isChecked, items);
        const newProducts = getNewProducts(product, products, isChecked);

        this.setState({
            productIds: newProductIds,
            products: newProducts
        });
    }

    getNewFinalPriceAndDiscount() {
        const { productIds } = this.state;
        const {
            cartBundle: {
                items,
                finalPrice: finalPriceHtml,
                discount: discountHtml,
                conditionalDiscount
            }
        } = this.props;

        const {
            isOnlyOne,
            returnedDiscount,
            returnedFinalPrice,
            isOverConditionalDiscount
        } = getFinalPriceAndDiscount(items, productIds, finalPriceHtml, discountHtml, conditionalDiscount);

        this.setState({
            isOnlyOne,
            isOverConditionalDiscount,
            newDiscount: returnedDiscount,
            newFinalPrice: returnedFinalPrice
        });
    }

    getFinalPrice() {
        const {
            newFinalPrice
        } = this.state;
        const {
            currency,
            cartBundle: {
                finalPrice
            }
        } = this.props;

        const actualFinalPrice = newFinalPrice === '' || newFinalPrice === finalPrice ? finalPrice : newFinalPrice;
        const floatFinalPrice = getFloatPrice(actualFinalPrice);
        const sign = formatPrice(0, currency).replace(/[,\d\w]/g, '');

        return sign + floatFinalPrice;
    }

    getFinalDiscount() {
        const {
            newDiscount
        } = this.state;
        const {
            currency,
            cartBundle: {
                discount
            }
        } = this.props;

        const actualDiscount = newDiscount === '' || newDiscount === discount ? discount : newDiscount;
        const floatDiscount = getFloatPrice(actualDiscount);
        const sign = formatPrice(0, currency).replace(/[,\d\w]/g, '');

        return sign + floatDiscount;
    }

    containerProps() {
        const {
            cartBundle: {
                title,
                packKey,
                items,
                itemClass,
                multiRowClass
            }
        } = this.props;
        const {
            isOnlyOne,
            products: checkedProducts,
            isOverConditionalDiscount,
            isAdding
        } = this.state;

        return {
            title,
            packKey,
            items,
            itemClass,
            discount: this.getFinalDiscount(),
            finalPrice: this.getFinalPrice(),
            isOnlyOne,
            isOverConditionalDiscount,
            checkedProducts: checkedProducts.length ? checkedProducts : this.getProducts(),
            multiRowClass,
            isAdding
        };
    }

    render() {
        const { cartMessage: { packId } } = this.props;

        if (!packId) {
            return null;
        }

        return (
            <BundlePack
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BundlePackContainer);
