/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { cloneElement } from 'react';

import { AmastyAutomaticRelatedProductsContext } from '../../context/AmastyAutomaticRelatedProducts';

export const wrapWithAmastyAutomaticRelatedProductsContext = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled } = {} } = instance.props;
    const Component = callback(...args);

    if (!isEnabled) {
        return Component;
    }

    return (
        <AmastyAutomaticRelatedProductsContext.Consumer>
            { (({
                fetchRelatedProducts,
                relatedProducts,
                bundlePacks,
                fetchBundlePacks
            }) => (
                cloneElement(Component, {
                    ...Component.props,
                    fetchRelatedProducts,
                    relatedProducts,
                    bundlePacks,
                    fetchBundlePacks
                }, Component.props.children)
            )) }
        </AmastyAutomaticRelatedProductsContext.Consumer>
    );
};

export default {
    'Route/CartPage/Container': {
        'member-function': {
            render: wrapWithAmastyAutomaticRelatedProductsContext
        }
    },
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            render: wrapWithAmastyAutomaticRelatedProductsContext
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            render: wrapWithAmastyAutomaticRelatedProductsContext
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            render: wrapWithAmastyAutomaticRelatedProductsContext
        }
    }
};
