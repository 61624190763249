import PropTypes from 'prop-types';
import { createRef } from 'react';

import AddToCart from 'Component/AddToCart';
import Link from 'Component/Link';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';
import {
    AttributeType
} from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';
import { showSizeGuidePopup } from 'Util/Popup';

/**
 * Product
 * @class Product
 * @namespace Frontend/Component/Product/Component
 */
export class Product extends SourceProduct {
    static propTypes = {
        ...SourceProduct.propTypes,
        customAttribute: AttributeType,
        customAttributeNew: AttributeType,
        customAttrubuteNewSetAs: PropTypes.bool,
        customAttributeComingSoon: AttributeType,
        setPickup: PropTypes.func,
        isProductExist: PropTypes.bool.isRequired
    };

    static defaultProps = {
        configFormRef: createRef(),
        customAttribute: null,
        customAttributeNew: null,
        customAttributeComingSoon: null,
        customAttrubuteNewSetAs: false,
        setPickup: noopFn
    };

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
            isProductExist
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                  numberOfPlaceholders={ [2, 4] }
                  updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                  addToCartTriggeredWithError={ addToCartTriggeredWithError }
                  mix={ { block: this.className, elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  showProductAttributeAsLink={ false }
                  isProductExist={ isProductExist }
                />
                { this.renderSizeGuideLink() }
            </div>
        );
    }

    renderSizeGuideLink() {
        /* set to true if Size Guide is a file */
        const isFileLink = true;

        if (isFileLink) {
            return (
                <div block="ProductActions" elem="SizeGuide">
                    <Link to="https://mana-scandi.loc/media/wysiwyg/guida_taglie.pdf" block="SizeGuide" elem="Link">{ __('Size guide') }</Link>
                </div>
            );
        }

        /* Size Guide POPUP */
        return (
            <div block="ProductActions" elem="SizeGuide">
                <button onClick={ showSizeGuidePopup } block="SizeGuide" elem="Link">{ __('Size guide') }</button>
            </div>
        );
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
            productPrice
        } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (inStock) {
            return (
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ product }
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                />
            );
        }

        return null;
    }

    renderBrand() {
        const { customAttribute } = this.props;

        if (!customAttribute) {
            return null;
        }

        return (
            <div
              block="Product"
              elem="Brand"
            >
                { Object.keys(customAttribute.attribute_options).map(() => (
                    <span block="LinkBrand">
                        <ProductAttributeValue
                          attribute={ customAttribute }
                          isFormattedAsText
                        />
                    </span>
                )) }
            </div>
        );
    }

    renderLabelNew() {
        const { customAttributeNew, customAttrubuteNewSetAs, customAttributeComingSoon } = this.props;

        const isSale = this.renderLabelSale();

        if ((customAttributeNew) && (customAttributeNew.attribute_value == 1) && (isSale)
        || (customAttrubuteNewSetAs) && (isSale)
        || (customAttributeNew) && (customAttributeNew.attribute_value == 1) && (customAttributeComingSoon) && (customAttributeComingSoon.attribute_value == 1)
        || (customAttributeNew) && (customAttributeNew.attribute_value == 1) && (customAttributeComingSoon == true)
        || (customAttrubuteNewSetAs) && (customAttributeComingSoon) && (customAttributeComingSoon.attribute_value == 1)
        || (customAttrubuteNewSetAs) && (customAttributeComingSoon == true)) {
            return (
                <div block="Label New small">
                    <span>{ __('New') }</span>
                </div>
            );
        }

        if ((customAttributeNew) && (customAttributeNew.attribute_value == 1) || (customAttrubuteNewSetAs)) {
            return (
                <div block="Label New">
                    <span>{ __('New') }</span>
                </div>
            );
        }

        return null;
    }

    renderLabelSale() {
        const {
            productPrice,
            productPrice: {
                price: { discount: { percentOff } }
            }
        } = this.props;

        const sale = Math.round(percentOff);

        if ((productPrice) && (percentOff > 0)) {
            return (
                <div block="Label Sale">
                    <span>-{ sale }%</span>
                </div>
            );
        }

        return null;
    }

    renderLabelComingSoon() {
        const { customAttributeComingSoon } = this.props;

        if ((customAttributeComingSoon) && (customAttributeComingSoon.attribute_value == 1) || (customAttributeComingSoon == true)) {
            return (
                <div block="Label ComingSoon">
                    <span>{ __('Coming soon') }</span>
                </div>
            );
        }

        return null;
    }

    renderPrice(isPreview = false) {
        const {
            getActiveProduct,
            productPrice
        } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderStock(visible = true) {
        const { inStock } = this.props;

        const stockStatusLabel = inStock ? __('In stock') : __('Out of stock');

        if (visible) {
            return <span block={ this.className } elem="Stock">{ stockStatusLabel }</span>;
        }

        return null;
    }

    renderSku() {
        const { getActiveProduct } = this.props;
        const { sku } = getActiveProduct();

        return <span block={ this.className } elem="Sku" itemProp="sku">{ __('SKU: %s', sku) }</span>;
    }

    renderName(header = true, dynamic = false) {
        const { product: { name }, productName } = this.props;
        const nameToRender = dynamic ? productName : name;

        if (!header) {
            return (
                <h4 block={ this.className } elem="Name">
                    <TextPlaceholder content={ nameToRender } length="medium" />
                </h4>
            );
        }

        return (
            <h1 block={ this.className } elem="Title" itemProp="name">
                    <TextPlaceholder content={ nameToRender } length="medium" />
            </h1>

        );
    }
}

export default Product;
