/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/ViewMutation/Query */
export class ViewMutationQuery {
    getQuery(blockId) {
        return new Field('amastyViewAnalytic')
            .addArgument('blockId', 'Int!', blockId)
            .addField('message');
    }
}

export default new ViewMutationQuery();
