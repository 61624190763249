/** override Sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductAlertsQuery from 'Query/ProductAlerts.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { StockStatusType } from 'Type/StockStatus.type';
import { noopFn } from 'Util/Common';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import ProductAlerts from './ProductAlerts.component';
import { PRODUCT_ALERT_IN_STOCK, PRODUCT_ALERT_PRICE_DROP } from './ProductAlerts.config';

/** @namespace Frontend/Component/ProductAlerts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isPriceAlertEnabled: state.ConfigReducer.product_alert_allow_price,
    isInStockAlertEnabled: state.ConfigReducer.product_alert_allow_stock,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Frontend/Component/ProductAlerts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Frontend/Component/ProductAlerts/Container */
export class ProductAlertsContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        productId: PropTypes.number.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isInStockAlertEnabled: PropTypes.bool,
        isPriceAlertEnabled: PropTypes.bool,
        stockStatus: StockStatusType,
        emailValue: PropTypes.string,
        handleEmailInput: PropTypes.func,
        isPriceAlert: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isInStockAlertEnabled: false,
        isPriceAlertEnabled: false,
        stockStatus: null,
        emailValue: '',
        handleEmailInput: noopFn
    };

    containerFunctions = {
        handlePriceDropSubscribeAlertPriceDrop: this.handlePriceDropSubscribe.bind(this, PRODUCT_ALERT_PRICE_DROP),
        handlePriceDropSubscribeAlertInStock: this.handlePriceDropSubscribe.bind(this, PRODUCT_ALERT_IN_STOCK),
        onFormSubmitSubscribe: this.onFormSubmitSubscribe.bind(this)
    };

    containerProps() {
        const {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            isSignedIn,
            handleEmailInput,
            isPriceAlert
        } = this.props;

        return {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            isSignedIn,
            handleEmailInput,
            isPriceAlert
        };
    }

    onFormSubmitSubscribe(form, fields) {
        const { isPriceAlert } = this.props;
        const alertType = !isPriceAlert ? PRODUCT_ALERT_IN_STOCK : PRODUCT_ALERT_PRICE_DROP;

        const {
            value: email
        } = fields.find(({ name }) => name === 'email') || {};

        this.handlePriceDropSubscribe(alertType, email);
    }

    async handlePriceDropSubscribe(type, email) {
        const {
            productId,
            showErrorNotification,
            showNotification,
            isSignedIn
        } = this.props;

        const query = isSignedIn ? ProductAlertsQuery.getProductAlertSubscribeMutation(productId, type) : ProductAlertsQuery.getProductAlertSubscribeMutation(productId, type, email);

        try {
            const productAlertSubscribe = await fetchMutation(query);

            if (productAlertSubscribe) {
                showNotification('success', __('You saved the alert subscription'));
            }
        } catch (error) {
            showErrorNotification(getErrorMessage(error));
        }
    }

    render() {
        return (
            <ProductAlerts
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertsContainer);
