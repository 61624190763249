/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapDispatchToProps } from 'Component/Product/Product.container';
import {
    mapStateToProps,
    ProductActionsContainer
} from 'Component/ProductActions/ProductActions.container';
import { getNewParameters, getVariantIndex } from 'Util/Product';

import ProductOptions from './ProductOptions.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductOptions/Container/ProductOptionsContainer */
export class ProductOptionsContainer extends ProductActionsContainer {
    static propTypes = {
        ...ProductActionsContainer.PropTypes,
        setActiveParameters: PropTypes.func.isRequired,
        setActiveQuantities: PropTypes.func.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions
    };

    updateConfigurableVariant(key, value, checkEmptyValue = false) {
        const { setActiveParameters, product } = this.props;
        const { parameters: prevParameters } = this.state;

        const newParameters = getNewParameters(prevParameters, key, value);

        const { [key]: oldValue, ...currentParameters } = newParameters;
        const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;
        setActiveParameters(parameters, product);
        this.setState({ parameters });

        const { product: { variants, configurable_options } } = this.props;
        const { selectedProduct } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        const newProduct = newIndex === -1 ? null : variants[newIndex];

        if (newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters
            });
        }
    }

    /**
     * Sets quantity, if grouped adds object over old,
     * if any other product updates value
     * @param quantity
     */
    setQuantity(quantity) {
        const { product, setActiveQuantities } = this.props;
        if (typeof quantity === 'object') {
            const { quantity: oldQuantity = {} } = this.state;

            this.setState({ quantity: { ...oldQuantity, ...quantity } });
        } else {
            this.setState({ quantity: +quantity });
        }

        setActiveQuantities(quantity, product);
    }

    containerProps() {
        return { ...super.containerProps() };
    }

    render() {
        return (
            <ProductOptions
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductOptionsContainer);
