/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import BundlePack from '../../component/BundlePack';

export const addAmastyBundlePackToRender = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled, isBlockInCartEnabled } = {} } = instance.props;

    if (!isEnabled || !isBlockInCartEnabled) {
        return callback(...args);
    }

    const Component = callback(...args);

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                const lastIndex = Component.props.children.length
                    ? Component.props.children.length - 1
                    : 0;

                if (i !== lastIndex) {
                    return child;
                }

                return (
                    <>
                        <BundlePack />
                        { child }
                    </>
                );
            }
        )
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render: addAmastyBundlePackToRender
        }
    }
};
