/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { BUNDLE_PACK_POSITION_TAB } from '../../component/BundlePack/BundlePack.config';
import BundlePacks from '../../component/BundlePacks';

export const getTabName = (instance) => {
    const { amastyARPConfig: { tabTitle = '' } = {} } = instance.props;
    const title = !tabTitle ? __('Bundle Packs') : __('%s', tabTitle);

    return title;
};

export const renderBundlePacksTab = (key, instance) => {
    const { dataSource: { id } } = instance.props;

    return <BundlePacks key={ key } id={ id } />;
};

export const addBundlePacksToTabs = (originalMembers, instance) => ({
    [getTabName(instance)]: {
        name: getTabName(instance),
        shouldTabRender: () => {
            const {
                bundlePacks,
                amastyARPConfig: {
                    isEnabled,
                    blockPosition = ''
                } = {}
            } = instance.props;

            return isEnabled && bundlePacks.length && blockPosition === BUNDLE_PACK_POSITION_TAB;
        },
        render: (key) => renderBundlePacksTab(key, instance)
    },
    ...originalMembers
});

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addBundlePacksToTabs
        }
    }
};
