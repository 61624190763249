/** extend Sidea */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import {
    ProductAlerts as SourceProductAlerts
} from 'SourceComponent/ProductAlerts/ProductAlerts.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './ProductAlerts.override.style';

/** @namespace Frontend/Component/ProductAlerts/Component */
export class ProductAlerts extends SourceProductAlerts {
    static propTypes = {
        ...SourceProductAlerts.propTypes,
        isSignedIn: PropTypes.bool.isRequired,
        emailValue: PropTypes.string.isRequired,
        onFormSubmitSubscribe: PropTypes.func.isRequired,
        handleEmailInput: PropTypes.func.isRequired,
        isPriceAlert: PropTypes.bool.isRequired
    };

    renderSubscribeFormGuest() {
        const {
            onFormSubmitSubscribe,
            emailValue,
            handleEmailInput
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onFormSubmitSubscribe }
            >
                <Field
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Your email address'),
                      defaultValue: emailValue
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ { onChange: handleEmailInput } }
                  addRequiredTag
                />
                <button block="Button primary">{ __('Notify me when in stock') }</button>
                <Field
                  type={ FIELD_TYPE.checkbox }
                  attr={ {
                      name: 'checkboxPrivacy',
                      'aria-label': __('Privacy acceptance')
                  } }
                  label={ __('By clicking Send, you declare that you accept the privacy policy and consent to the processing of personal data for subscribing to product notifications.') }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.checkbox,
                      isRequired: true
                  } }
                  addRequiredTag
                />
            </Form>
        );
    }

    renderInStockSubscribeButton() {
        const {
            handlePriceDropSubscribeAlertInStock,
            isInStockAlertEnabled,
            stockStatus,
            isSignedIn
        } = this.props;

        if (!isInStockAlertEnabled) {
            return null;
        }

        if (stockStatus === STOCK_TYPE.IN_STOCK || !stockStatus) {
            return null;
        }

        if (!isSignedIn) {
            return this.renderSubscribeFormGuest();
        }

        return (
            <button
              block="Button primary"
              onClick={ handlePriceDropSubscribeAlertInStock }
            >
                { __('Notify me when in stock') }
            </button>
        );
    }

    renderPriceDropSubscribeButton() {
        const {
            handlePriceDropSubscribeAlertPriceDrop,
            isPriceAlertEnabled,
            isSignedIn
        } = this.props;

        if (!isPriceAlertEnabled) {
            return null;
        }

        if (!isSignedIn) {
            return (
                <div
                  block="ProductAlerts"
                  elem="PriceDropWrapper"
                >
                    <button
                      block="ProductAlerts"
                      elem="PriceDrop"
                    >
                        { __('Notify me when the price drops') }
                    </button>
                    { this.renderSubscribeFormGuest() }
                </div>
            );
        }

        return (
            <button
              block="ProductAlerts"
              elem="PriceDrop"
              onClick={ handlePriceDropSubscribeAlertPriceDrop }
            >
                { __('Notify me when the price drops') }
            </button>
        );
    }

    render() {
        const { isSignedIn, isPriceAlert } = this.props;
        const Guest = !isSignedIn ? 'Guest' : '';
        const priceAlertOn = isPriceAlert ? this.renderPriceDropSubscribeButton() : '';
        const stockAlertOn = !isPriceAlert ? this.renderInStockSubscribeButton() : '';

        return (
            <div
              block="ProductAlerts"
              elem="Wrapper"
              mix={ { block: Guest } }
            >
                { priceAlertOn }
                { stockAlertOn }
            </div>
        );
    }
}

export default ProductAlerts;
