/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import Related from '../../component/Related';
import {
    RELATED_CART,
    RELATED_POSITION_BOTTOM,
    RELATED_POSITION_CROSSEL,
    RELATED_POSITION_TOP,
    RELATED_TO_AFTER,
    RELATED_TO_BEFORE,
    RELATED_TO_CONTENT
} from '../../component/Related/Related.config';
import { getPositions } from '../../util/AmastyAutomaticRelatedProducts';

export const addAmastyRelatedProductsToRender = (args, callback, instance) => {
    const {
        amastyARPConfig: {
            isEnabled
        } = {},
        totals: {
            items
        },
        relatedProducts
    } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_CART);
    const { id } = items[Math.floor(Math.random() * items.length)] || {};

    const Component = callback(...args);

    return cloneElement(
        Component,
        Component.props,
        Children.map(
            Component.props.children,
            (child, i) => {
                const lastIndex = Component.props.children.length
                    ? Component.props.children.length - 1
                    : 0;

                if (i === lastIndex) {
                    if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_BOTTOM) {
                        return (
                            <>
                                { child }
                                <Related relatedType={ RELATED_CART } id={ +id } />
                            </>
                        );
                    }

                    return child;
                }

                if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_TOP) {
                    return (
                        <>
                            <Related relatedType={ RELATED_CART } id={ +id } />
                            { child }
                        </>
                    );
                }

                return child;
            }
        )
    );
};

export const addAmastyRelatedProductsToCrossSell = (args, callback, instance) => {
    const {
        amastyARPConfig: {
            isEnabled
        } = {},
        totals: {
            items
        },
        relatedProducts
    } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_CART);
    const { id } = items[Math.floor(Math.random() * items.length)] || {};

    if (positionRelateTo === RELATED_TO_BEFORE && positionPlace === RELATED_POSITION_CROSSEL) {
        return (
            <>
                <Related relatedType={ RELATED_CART } id={ +id } />
                { callback(...args) }
            </>
        );
    }

    if (positionRelateTo === RELATED_TO_AFTER && positionPlace === RELATED_POSITION_CROSSEL) {
        return (
            <>
                { callback(...args) }
                <Related relatedType={ RELATED_CART } id={ +id } />
            </>
        );
    }

    return callback(...args);
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderCrossSellProducts: addAmastyRelatedProductsToCrossSell,
            render: addAmastyRelatedProductsToRender
        }
    }
};
