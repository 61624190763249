/** Component Sidea - Popup guida taglie in pagina dettaglio prodotto */
import { Component } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';
import { SIZE_GUIDE_POPUP_ID } from 'Component/Popup/Popup.config';

import './SizeGuidePopup.style';

/** @namespace Frontend/Component/SizeGuidePopup/Component */
export class SizeGuidePopup extends Component {
    render() {
        return (
            <Popup
              id={ SIZE_GUIDE_POPUP_ID }
              mix={ { block: 'SizeGuide', elem: 'Popup' } }
            >
                <CmsBlock identifier="popup-guida-taglie" />
            </Popup>
        );
    }
}

export default SizeGuidePopup;
