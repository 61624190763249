/** Override Sidea */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CompareIcon.style';

/** @namespace Frontend/Component/CompareIcon/Component */
export class CompareIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CompareIcon"
              mods={ { isActive } }
              width="23.16"
              height="19.642"
              viewBox="0 0 23.16 19.642"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M25.15,16.651,19.211,22.59a.891.891,0,1,1-1.259-1.259l4.417-4.418H6.7a.891.891,0,1,1,0-1.782H24.52a.891.891,0,0,1,.63,1.52Zm-3.3-6.568a.891.891,0,0,0-.891-.891H5.292L9.709,4.774A.891.891,0,1,0,8.45,3.515L2.511,9.454a.891.891,0,0,0,.63,1.52H20.957a.891.891,0,0,0,.891-.891Z" transform="translate(-2.251 -3.231)" />
            </svg>
        );
    }
}

export default CompareIcon;
