/** override Sidea */

import PropTypes from 'prop-types';
import { Suspense } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import InfoSpedizionePopup from 'Component/InfoSpedizionePopup';
import Loader from 'Component/Loader/Loader.component';
import ProductActions from 'Component/ProductActions';
import ProductTabs from 'Component/ProductTabs';
import SizeGuidePopup from 'Component/SizeGuidePopup';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    PRODUCT_ATTRIBUTES,
    PRODUCT_INFORMATION,
    PRODUCT_REVIEWS
} from 'Route/ProductPage/ProductPage.config';
import {
    ProductAttributes as SourceProductAttributes,
    ProductGallery as SourceProductGallery,
    ProductPage as SourceProductPage,
    ProductReviews as SourceProductReviews
} from 'SourceRoute/ProductPage/ProductPage.component';

import './ProductPage.style';

export const ProductGallery = SourceProductGallery;

export const ProductReviews = SourceProductReviews;

export const ProductAttributes = SourceProductAttributes;

/** @namespace Frontend/Route/ProductPage/Component */
export class ProductPage extends SourceProductPage {
    static propTypes = {
        ...SourceProductPage.propTypes,
        isMobile: PropTypes.bool.isRequired,
        areReviewsEnabled: PropTypes.bool.isRequired,
        getDynamicSkuForAttributes: PropTypes.func.isRequired,
        dynamicSku: PropTypes.string.isRequired
    };

    tabMap = {
        [PRODUCT_INFORMATION]: {
            name: __('Description'),
            anchorName: 'description-tab',
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key)
        },
        [PRODUCT_ATTRIBUTES]: {
            name: __('Product details'),
            anchorName: 'advantages',
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key)
            /* render: () => this.renderShopAdvantages() /* per visualizzare blocco CMS vantaggi-acquisto */
        },
        [PRODUCT_REVIEWS]: {
            name: __('Reviews'),
            anchorName: 'reviews',
            shouldTabRender: () => true,
            render: (key) => this.renderProductReviewsTab(key)
        }
    };

    renderProductAttributesTab(key) {
        const {
            activeProduct,
            areDetailsLoaded,
            dynamicSku
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                <ProductAttributes
                  product={ activeProduct }
                  dynamicSku={ dynamicSku }
                  areDetailsLoaded={ areDetailsLoaded }
                  key={ key }
                />
            </Suspense>
        );
    }

    renderShareBlock() {
        return (
            <div block="Share" elem="Reviews">
                <div block="p14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.777" viewBox="0 0 22 19.777">
                        <g id="Raggruppa_73611" data-name="Raggruppa 73611" transform="translate(1 1)">
                            <circle id="Ellisse_95" data-name="Ellisse 95" cx="3" cy="3" r="3" transform="translate(13.961)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <circle id="Ellisse_96" data-name="Ellisse 96" cx="3" cy="3" r="3" transform="translate(0 5.889)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <circle id="Ellisse_97" data-name="Ellisse 97" cx="3" cy="3" r="3" transform="translate(14 11.777)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <line id="Linea_333" data-name="Linea 333" x2="6.83" y2="3.98" transform="translate(6.589 9.914)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <line id="Linea_334" data-name="Linea 334" x1="6.82" y2="3.98" transform="translate(6.59 3.885)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        </g>
                    </svg>
                    { __('Share') }
                </div>
                <p block="p14">Trustpilot widget</p>
            </div>
        );
    }

    renderSkuAndStock() {
        const { dynamicSku } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'sku' } }
              aria-label="Product SKU and availability"
            >
                <span block="ProductActions" elem="Sku" itemProp="sku">{ __('SKU: %s', dynamicSku) }</span>
            </section>
        );
    }

    renderNameMobile() {
        const {
            isMobile,
            dataSource: {
                name,
                attributes
            }
        } = this.props;

        const manufacturer = attributes !== undefined ? attributes.manufacturer : '';

        const brandName = manufacturer ? Object.values(manufacturer.attribute_options).map((item) => {
            const brand = item.label;

            return (
                <div block="Product-Brand">
                    <span block="ProductAttributeValue">
                        { brand }
                    </span>
                </div>
            );
        }) : '';

        if (isMobile) {
            return (
                <article block="ProductActions" mix={ { block: 'TopMobile' } }>
                    { brandName }
                    <h1 block="ProductActions" elem="Title">
                        { name }
                    </h1>
                    { this.renderSkuAndStock() }
                </article>
            );
        }

        return null;
    }

    renderProductPageContent() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            activeProduct,
            setActiveProduct,
            useEmptyGallerySwitcher,
            parameters,
            isVariant,
            getDynamicSkuForAttributes
        } = this.props;

        /* per renderizzare brand, nome prodotto, sku prima dell'immagine set attivate to true */
        const attivate = false;
        const productNameFirst = attivate ? this.renderNameMobile() : '';

        return (
            <>
                { productNameFirst }
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                />
                <ProductActions
                  getLink={ getLink }
                  product={ dataSource }
                  parameters={ parameters }
                  areDetailsLoaded={ areDetailsLoaded }
                  setActiveProduct={ setActiveProduct }
                  getDynamicSkuForAttributes={ getDynamicSkuForAttributes }
                />
                { this.renderShareBlock() }
            </>
        );
    }

    renderShopAdvantages() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('The advantages of the purchase') : '';

        return (
            <ContentWrapper
              label="Product information"
              mix={ { block: 'ProductInformation ShopAdvantages' } }
              wrapperMix={ { block: 'ProductInformation', elem: 'Wrapper' } }
            >
                <ExpandableContent
                  /* show placeholder if the details are not loaded */
                  heading={ heading }
                  mix={ { block: 'ProductInformation', elem: 'Content' } }
                >
                    <CmsBlock identifier="vantaggi-acquisto" />
                </ExpandableContent>
            </ContentWrapper>
        );
    }

    renderProductTabs() {
        const tabs = this.shouldTabsRender();
        const { areReviewsEnabled } = this.props;

        if (!tabs) {
            return null;
        }

        return (
              <ProductTabs tabs={ tabs } areReviewsEnabled={ areReviewsEnabled } />
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                    </ContentWrapper>

                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                    <InfoSpedizionePopup />
                    <SizeGuidePopup />
                    <CmsBlock identifier="banner-click-and-collect" />
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPage;
