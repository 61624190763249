/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CartIcon from 'Component/CartIcon';
import { ProductType } from 'Type/ProductList.type';
import { getIndexedProduct } from 'Util/Product';

import BundleProductsPage from '../BundleProductsPage';

import './ProductsPopUp.style';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductsPopUp/Component/ProductsPopUpComponent */
export class ProductsPopUpComponent extends PureComponent {
    static propTypes = {
        products: PropTypes.arrayOf(ProductType).isRequired,
        setActiveProducts: PropTypes.func.isRequired,
        setActiveParameters: PropTypes.func.isRequired,
        addToCart: PropTypes.func.isRequired,
        setActiveQuantities: PropTypes.func.isRequired,
        isAdding: PropTypes.bool.isRequired
    };

    renderProductPageContent = (dataSource) => {
        const {
            sku,
            id
        } = dataSource;

        const {
            setActiveProducts,
            setActiveParameters,
            setActiveQuantities
        } = this.props;

        const product = getIndexedProduct(dataSource, sku);

        if (!sku) {
            return null;
        }

        return (
            <BundleProductsPage
              key={ sku }
              productSKU={ sku }
              productID={ id }
              product={ product }
              setActiveProducts={ setActiveProducts }
              setActiveParameters={ setActiveParameters }
              setActiveQuantities={ setActiveQuantities }
            />
        );
    };

    renderProducts() {
        const { products } = this.props;

        if (products.length === 0) {
            return null;
        }

        return products.map(this.renderProductPageContent);
    }

    renderCartIcon() {
        return <CartIcon />;
    }

    renderAddToCart() {
        const { addToCart, isAdding } = this.props;
        const text = isAdding ? __('Adding...') : __('Add to cart');
        return (
            <button
              onClick={ addToCart }
              block="Button AddToCart"
              mix={ { block: 'ProductsPopUp', elem: 'AddToCart' } }
              disabled={ isAdding }
            >
                { this.renderCartIcon() }
                <span>{ text }</span>
            </button>
        );
    }

    render() {
        return (
            <div block="ProductsPopUp">
                { this.renderProducts() }
                { this.renderAddToCart() }
            </div>
        );
    }
}

export default ProductsPopUpComponent;
