/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PackList from '../component/PackList';
import { PACK_LIST_CMS_TYPE } from '../component/PackList/PackList.config';
import ProductBundlePack from '../component/ProductBundlePack';
import { PRODUCT_BUNDLE_PACK_CMS_TYPE } from '../component/ProductBundlePack/ProductBundlePack.config';
import Related from '../component/Related';
import { RELATED_CMS_TYPE } from '../component/Related/Related.config';

export const addAmastyWidgetsToWidgetFactory = (member) => ({
    ...member,
    [PACK_LIST_CMS_TYPE]: {
        component: PackList
    },
    [RELATED_CMS_TYPE]: {
        component: Related
    },
    [PRODUCT_BUNDLE_PACK_CMS_TYPE]: {
        component: ProductBundlePack
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addAmastyWidgetsToWidgetFactory
        }
    }
};
