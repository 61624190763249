/* Extend Sidea */
import PropTypes from 'prop-types';

import Link from 'Component/Link';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace Frontend/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    static propTypes = {
        ...SourceProductConfigurableAttributes.propTypes,
        isProductExist: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductConfigurableAttributes.defaultProps,
        isProductExist: true

    };

    renderButtonSizeGuide(attribute_code) {
        if (attribute_code == 'size') {
            return <Link block="Button" elem="Text" to="/media/wysiwyg/guida.pdf" target="_blank">{ __('Size guide') }</Link>;
        }

        return null;
    }

    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            inStock,
            isProductExist
        } = this.props;

        const { attribute_value, attribute_values } = attribute;
        const isOnLoad = 'true';

        if (attribute_values.length > 1) {
            const isOnLoad = 'false';

            return (
                    <ProductAttributeValue
                      key={ attribute_value }
                      attribute={ attribute }
                      isSelected={ isSelected(attribute) }
                      isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
                      onClick={ handleOptionClick }
                      oneVariant={ isOnLoad }
                      getLink={ getLink }
                      showProductAttributeAsLink={ showProductAttributeAsLink }
                      isProductExist={ isProductExist }
                      inStock={ inStock }
                    />
            );
        }

        return (
                <ProductAttributeValue
                  key={ attribute_value }
                  attribute={ attribute }
                  isSelected={ isSelected(attribute) }
                  isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
                  onClick={ handleOptionClick }
                  oneVariant={ isOnLoad }
                  getLink={ getLink }
                  showProductAttributeAsLink={ showProductAttributeAsLink }
                  isProductExist={ isProductExist }
                  inStock={ inStock }
                />
        );
    }

    renderDropdown(option, isUnselected) {
        const {
            updateConfigurableVariant,
            getIsConfigurableAttributeAvailable,
            parameters,
            handleShakeAnimationEnd
        } = this.props;

        return (
            <ProductConfigurableAttributeDropdown
              handleShakeAnimationEnd={ handleShakeAnimationEnd }
              isUnselected={ isUnselected }
              option={ option }
              updateConfigurableVariant={ updateConfigurableVariant }
              getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeAvailable }
              parameters={ parameters }
            />
        );
    }

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable,
            inStock,
            handleShakeAnimationEnd,
            addToCartTriggeredWithError,
            parameters
        } = this.props;

        // Ordina configurable_options in base all'ordine desiderato
        const order = ['color', 'size'];
        const orderedConfigurableOptions = Object.keys(configurable_options)
            .sort((a, b) => order.indexOf(a) - order.indexOf(b))
            .reduce((accumulator, key) => ({
                ...accumulator,
                [key]: configurable_options[key]
            }), {});

        return Object.values(orderedConfigurableOptions).map((option) => {
            const {
                attribute_code,
                attribute_label,
                attribute_options,
                attribute_id
            } = option;
            const isUnselected = addToCartTriggeredWithError ? !parameters[attribute_code] : null;
            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option);
            }

            if (!inStock && !isSwatch) {
                return this.renderDropdown(option, isUnselected);
            }

            const selectedOption = parameters[attribute_code];
            const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

            return (
                <div id={ attribute_code } key={ attribute_id }>
                    <p
                      block="ProductConfigurableAttributes"
                      elem="Title"
                      mods={ { isUnselected } }
                      onAnimationEnd={ handleShakeAnimationEnd }
                    >
                        { attribute_label }
                        { isSwatch && (
                            <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                                { selectedOptionLabel }
                            </span>
                        ) }
                    </p>
                    { isSwatch ? this.renderSwatch(option, isUnselected) : this.renderDropdown(option, isUnselected) }
                </div>
            );
        });
    }

    renderSwatch(option, isUnselected) {
        const {
            handleShakeAnimationEnd
        } = this.props;
        const { attribute_values, attribute_code } = option;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              mods={ { isUnselected } }
              key={ attribute_code }
              onAnimationEnd={ handleShakeAnimationEnd }
            >
                { attribute_values.map((attribute_value) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value })
                )) }
                { this.renderButtonSizeGuide(attribute_code) }
            </div>
        );
    }
}

export default ProductConfigurableAttributes;
