/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useState } from 'react';

import { ChildrenType } from 'Type/Common.type';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import {
    AMASTY_BLOCK_MAP,
    RELATED_CART,
    // RELATED_CART_CACHE,
    // RELATED_ONE_MINUTE_IN_SECONDS,
    RELATED_PRODUCT
} from '../component/Related/Related.config';
import BundlePacksQuery from '../query/BundlePacks.query';
import PackListQuery from '../query/PackList.query';
import RelatedProductQuery from '../query/RelatedProduct.query';
import ViewMutationQuery from '../query/ViewMutation.query';

export const AmastyAutomaticRelatedProductsContext = createContext({
    bundlePacks: [],
    setBundlePacks: () => {},
    bundlePackMainId: 0,
    setBundlePackMainId: () => {},
    relatedProducts: [],
    setRelatedProducts: () => {},
    relatedMainId: 0,
    setRelatedMainId: () => {},
    relatedCache: 0,
    setRelatedCache: () => {},
    packList: [],
    setPackList: () => {},
    packListTotalPages: 0,
    setPackListTotalPages: () => {}
});

AmastyAutomaticRelatedProductsContext.displayName = 'AmastyAutomaticRelatedProductsContext';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Context/AmastyAutomaticRelatedProducts/AmastyAutomaticRelatedProductsProvider */
export const AmastyAutomaticRelatedProductsProvider = ({ children }) => {
    const [bundlePacks, setBundlePacks] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [relatedMainId, setRelatedMainId] = useState(0);
    const [bundlePackMainId, setBundlePackMainId] = useState(0);
    // const [relatedCache, setRelatedCache] = useState(0);
    const [packList, setPackList] = useState([]);
    const [packListTotalPages, setPackListTotalPages] = useState(0);

    const fetchPackList = async (currentPage = 1) => {
        const query = [PackListQuery.getQuery(currentPage)];
        const {
            packList
        } = await executeGet(prepareQuery(query), 'PackList', ONE_MONTH_IN_SECONDS);

        const { totalPages } = packList[0] || {};
        setPackList(packList);
        setPackListTotalPages(totalPages);
    };

    const fetchBundlePacks = async (productId) => {
        if (bundlePackMainId === productId) {
            return;
        }

        setBundlePackMainId(productId);

        const query = [BundlePacksQuery.getQuery(productId)];
        const {
            bundles
        } = await executeGet(prepareQuery(query), 'AmastyBundlePacks', ONE_MONTH_IN_SECONDS);

        setBundlePacks(bundles);
    };

    const fetchRelatedProducts = async (itemId, relatedPosition) => {
        console.log('qua entro sempre', itemId);
        if (!itemId || relatedMainId === itemId) {
            return;
        }

        // const time = (new Date().getTime()) / RELATED_CART_CACHE;

        // // vvv Prevent from fetching multiple times
        // if (
        //     time - relatedCache <= RELATED_ONE_MINUTE_IN_SECONDS
        // ) {
        //     return;
        // }

        // setRelatedCache((new Date().getTime()) / RELATED_CART_CACHE);

        setRelatedMainId(itemId);

        const position = relatedPosition === RELATED_CART ? RELATED_PRODUCT : relatedPosition;
        const blockId = AMASTY_BLOCK_MAP[relatedPosition];

        const query = [RelatedProductQuery.getQuery(itemId, position)];
        const {
            related: {
                items
            }
        } = await executeGet(prepareQuery(query), 'AmastyRelated', ONE_MONTH_IN_SECONDS);

        setRelatedProducts(items);

        await fetchMutation(ViewMutationQuery.getQuery(blockId));
    };

    const value = {
        bundlePacks,
        fetchBundlePacks,
        relatedProducts,
        fetchRelatedProducts,
        packList,
        fetchPackList,
        packListTotalPages
    };

    return (
         <AmastyAutomaticRelatedProductsContext.Provider value={ value }>
             { children }
         </AmastyAutomaticRelatedProductsContext.Provider>
    );
};

AmastyAutomaticRelatedProductsProvider.displayName = 'AmastyAutomaticRelatedProductsProvider';

AmastyAutomaticRelatedProductsProvider.propTypes = {
    children: ChildrenType.isRequired
};
