import PropTypes from 'prop-types';

import {
    ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

/** @namespace Frontend/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    static propTypes = {
        ...SourceProductConfigurableAttributesContainer.propTypes,
        isProductExist: PropTypes.bool
    };

    static defaultProps = {
        ...SourceProductConfigurableAttributesContainer.defaultProps,
        isProductExist: true

    };

    containerProps() {
        const {
            isProductExist
        } = this.props;

        return {
            isProductExist,
            ...super.containerProps()
        };
    }
}

export default ProductConfigurableAttributesContainer;
