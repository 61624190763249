/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';
/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/RelatedProduct/Query */
export class RelatedProductQuery {
    getQuery(id, position) {
        console.log('id query', id, position);
        return new Field('related')
            .addArgument('id', 'Int!', id)
            .addArgument('position', 'String!', position)
            .addField(this.getItems());
    }

    getItems() {
        return new Field('items')
            .addFieldList(this.getItemsField());
    }

    getItemsField() {
        return [
            'blockTitle',
            'blockLayout',
            'addToCart',
            'position',
            'type',
            this.getItemsFields()
        ];
    }

    getItemsFields() {
        return new Field('items')
            .addFieldList(ProductListQuery._getProductInterfaceFields());
    }
}

export default new RelatedProductQuery();
