import Link from 'Component/Link';
import {
    PaginationLink as SourcePaginationLink
} from 'SourceComponent/PaginationLink/PaginationLink.component';

import './PaginationLink.override.style';

/** @namespace Frontend/Component/PaginationLink/Component */
export class PaginationLink extends SourcePaginationLink {
    scrollToTop() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    render() {
        const {
            label,
            url_path: pathname,
            isCurrent,
            children
        } = this.props;

        const search = this.getSearchQueryForPage();

        const { state = {} } = history.state || {};

        return (
            <Link
              to={ {
                  search,
                  pathname,
                  state
              } }
              aria-label={ label }
              block="PaginationLink"
              mods={ { isCurrent, isArrow: typeof children !== 'string' } }
              aria-current={ isCurrent ? 'page' : 'false' }
              onClick={ this.scrollToTop }
            >
                { children }
            </Link>
        );
    }
}

export default PaginationLink;
