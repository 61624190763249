/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import { showNewPopup } from '../../util/AmastyAutomaticRelatedProducts';
import {
    BundlePackContainer,
    mapDispatchToProps
} from '../BundlePack/BundlePack.container';
import ProductBundlePack from './ProductBundlePack.component';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductBundlePack/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductBundlePack/Container/ProductBundlePackContainer */
export class ProductBundlePackContainer extends BundlePackContainer {
    static propTypes = {
        ...BundlePackContainer.PropTypes,
        index: PropTypes.number.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions
    };

    async handleButtonClick() {
        const { products } = this.state;
        const { fallbackAddToCart, index } = this.props;

        if (products.length === 0) {
            return;
        }

        this.setState({ isAdding: true });

        const hasConfigurableOptions = products.filter((product) => {
            const { configurable_options } = product;

            if (configurable_options && configurable_options.length > 0) {
                return product;
            }

            return null;
        });

        if (hasConfigurableOptions.length > 0) {
            this.setState({ isAdding: false });
            showNewPopup(index);
            return;
        }

        const compatibleProducts = products.map((product) => magentoProductTransform(ADD_TO_CART, product, 1)[0]);

        await fallbackAddToCart({ products: compatibleProducts });
        this.setState({ isAdding: false });
    }

    containerProps() {
        const { index } = this.props;

        return {
            ...super.containerProps(),
            index
        };
    }

    render() {
        return (
            <ProductBundlePack
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBundlePackContainer);
