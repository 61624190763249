/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Query/AmConfig/Query */
export class AmConfigQuery {
    getQuery() {
        return new Field('amAutomaticRelatedProducts')
            .addFieldList(this.getConfigFields());
    }

    getConfigFields() {
        return [
            'isEnabled',
            'confirmationTitle',
            'isCartEnabledOnProductPage',
            'isProductsCanBeAddedSeparately',
            'isApplyCartRule',
            'isMessageInCartEnabled',
            'isIgnoreAnchorCategories',
            'isBlockInCartEnabled',
            'isTopMenuEnabled',
            'blockPosition',
            'tabTitle',
            'cmsPageUrlKey',
            'menuLabel',
            'topMenuOrder'
        ];
    }
}

export default new AmConfigQuery();
