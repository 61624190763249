/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import NoMatchHandler from 'Route/NoMatchHandler';

import { BUNDLE_PACK_POSITION_BELOW } from '../../component/BundlePack/BundlePack.config';
import BundlePacks from '../../component/BundlePacks';
import Related from '../../component/Related';
import {
    RELATED_POSITION_BOTTOM,
    RELATED_POSITION_RECOMMENDED,
    RELATED_POSITION_TAB,
    RELATED_POSITION_TOP,
    RELATED_POSITION_UPSELL,
    RELATED_PRODUCT,
    RELATED_TO_AFTER,
    RELATED_TO_BEFORE,
    RELATED_TO_CONTENT
} from '../../component/Related/Related.config';
import { getPositions, getProduct } from '../../util/AmastyAutomaticRelatedProducts';

export const addRelatedProductsToAdditionalSections = (args, callback, instance) => {
    const {
        dataSource: { id },
        fetchRelatedProducts,
        fetchBundlePacks,
        relatedProducts = [],
        amastyARPConfig: { isEnabled, blockPosition = '' } = {}
    } = instance.props;

    if (!isEnabled) {
        return callback(...args);
    }

    fetchBundlePacks(id);
    console.log('passo dal product', id, RELATED_PRODUCT);
    fetchRelatedProducts(id, RELATED_PRODUCT);

    const renderBundlePacks = blockPosition !== BUNDLE_PACK_POSITION_BELOW ? null : <BundlePacks id={ id } />;

    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_PRODUCT);

    if (!positionRelateTo) {
        return callback(...args);
    }

    const Component = callback(...args);

    // vvv Adding related products by position from config
    return (
        <>
            { cloneElement(
                Component,
                Component.props,
                Children.map(
                    Component.props.children,
                    (child) => {
                        if (child.props.tabs) {
                            if (positionRelateTo === RELATED_TO_BEFORE && positionPlace === RELATED_POSITION_TAB) {
                                return (
                                    <>
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                        { child }
                                        { renderBundlePacks }
                                    </>
                                );
                            }

                            if (positionRelateTo === RELATED_TO_AFTER && positionPlace === RELATED_POSITION_TAB) {
                                return (
                                    <>
                                        { child }
                                        { renderBundlePacks }
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                    </>
                                );
                            }
                        }

                        if (child.props.linkType && child.props.linkType === RELATED_POSITION_RECOMMENDED) {
                            if (
                                positionRelateTo === RELATED_TO_BEFORE
                                 && positionPlace === RELATED_POSITION_RECOMMENDED
                            ) {
                                return (
                                    <>
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                        { child }
                                    </>
                                );
                            }

                            if (
                                positionRelateTo === RELATED_TO_AFTER
                                 && positionPlace === RELATED_POSITION_RECOMMENDED
                            ) {
                                return (
                                    <>
                                        { child }
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                    </>
                                );
                            }
                        }

                        if (child.props.linkType && child.props.linkType === RELATED_POSITION_UPSELL) {
                            if (positionRelateTo === RELATED_TO_BEFORE && positionPlace === RELATED_POSITION_UPSELL) {
                                return (
                                    <>
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                        { child }
                                    </>
                                );
                            }

                            if (positionRelateTo === RELATED_TO_AFTER && positionPlace === RELATED_POSITION_UPSELL) {
                                return (
                                    <>
                                        { child }
                                        <Related relatedType={ RELATED_PRODUCT } id={ id } />
                                    </>
                                );
                            }
                        }

                        return child;
                    }
                )
            ) }
        </>
    );
};

export const addRelatedProductsToRender = (args, callback, instance) => {
    const { amastyARPConfig: { isEnabled } = {}, relatedProducts } = instance.props;
    const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_PRODUCT);

    if (!isEnabled || !positionRelateTo) {
        return callback(...args);
    }

    const Component = callback(...args);

    // vvv Adding related products by position from config
    return (
        <NoMatchHandler>
            { cloneElement(
                Component,
                Component.props,
                Children.map(
                    Component.props.children,
                    (child, i) => {
                        const lastIndex = Component.props.children.length
                            ? Component.props.children.length - 1
                            : 0;

                        if (i === lastIndex) {
                            if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_BOTTOM) {
                                return (
                                    <>
                                        { child }
                                        <Related relatedType={ RELATED_PRODUCT } />
                                    </>
                                );
                            }

                            return child;
                        }

                        if (positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_TOP) {
                            return (
                                <>
                                    <Related relatedType={ RELATED_PRODUCT } />
                                    { child }
                                </>
                            );
                        }

                        return child;
                    }
                )
            ) }
        </NoMatchHandler>
    );
};

export const getTabName = (instance) => {
    const { relatedProducts } = instance.props;

    const { blockTitle = '' } = getProduct(relatedProducts, RELATED_PRODUCT) || {};

    const title = blockTitle ? __('%s', blockTitle) : __('Who Viewed This Also Viewed');

    return title;
};

export const renderRelatedProductsTab = (key) => (
         <Related key={ key } relatedType={ RELATED_PRODUCT } />
);

export const addRelatedProductsToTabs = (originalMembers, instance) => ({
    [getTabName(instance)]: {
        name: getTabName(instance),
        shouldTabRender: () => {
            const { relatedProducts } = instance.props;
            const { positionRelateTo, positionPlace } = getPositions(relatedProducts, RELATED_PRODUCT);

            return !!positionPlace && positionRelateTo === RELATED_TO_CONTENT && positionPlace === RELATED_POSITION_TAB;
        },
        render: (key) => renderRelatedProductsTab(key)
    },
    ...originalMembers
});

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addRelatedProductsToTabs
        },
        'member-function': {
            render: addRelatedProductsToRender,
            renderAdditionalSections: addRelatedProductsToAdditionalSections
        }
    }
};
