/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductListQuery from 'Query/ProductList.query';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import { CART_POPUP_ID, HUNDRED } from '../component/BundlePack/BundlePack.config';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getNewProductIds */
export const getNewProductIds = (productIds, id, isChecked, items) => {
    const { id: mainProductId } = items.find((item) => item.isMainProduct);
    const productIdsArray = productIds.split(',');
    const isMainProductIdInArray = productIdsArray.some((item) => item === mainProductId);

    if (!isMainProductIdInArray) {
        productIdsArray.push(mainProductId);
    }

    if (isChecked) {
        const newProductIdsArray = productIdsArray.concat(id);

        return newProductIdsArray.toString();
    }

    const newProductIdsArray = productIdsArray.filter((item) => item !== id);

    return newProductIdsArray.toString();
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getNewProducts */
export const getNewProducts = (product, products, isChecked) => {
    if (isChecked) {
        return products.concat(product);
    }

    const { id: productId } = product;
    const newProducts = products.filter(({ id }) => id !== productId);

    return newProducts;
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/showNewPopup */
export const showNewPopup = (index = '') => {
    const { dispatch } = getStore();

    dispatch(showPopup(CART_POPUP_ID + index, { title: __('Choose Options') }));
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getFloatPrice */
export const getFloatPrice = (price) => parseFloat(price.replace(/[^\d.-]/g, '')).toFixed(2);

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getFinalPriceAndDiscount */
export const getFinalPriceAndDiscount = (items, childIds, finalPriceHtml, discountHtml, conditionalDiscount) => {
    // vvv Check for main product
    const { id: parentId, productPriceHtml } = items.find((item) => item.isMainProduct);
    const mainProductPrice = getFloatPrice(productPriceHtml);
    const ids = childIds.split(',');
    // vvv Check for conditional discount limit of products
    const isOverConditionalDiscount = conditionalDiscount > ids.length;
    // vvv If has only one product we need to disable discount
    const isOnlyOne = (ids.length === 1 && ids[0] === parentId) || ids.length === 0;
    const finalDiscount = getFloatPrice(discountHtml);
    // vvv Getting products that was not checked
    const products = items.filter((item) => {
        const { id } = item;
        const excl = ids.find((item) => item === id);
        if (excl) {
            return null;
        }

        return item;
    });

    // vvv Getting sum of prices of uncheked products
    const uncheckedProductsSum = products.reduce((acc, item) => {
        const { productPriceHtml } = item;
        return acc + +getFloatPrice(productPriceHtml);
    }, 0.00);

    const finalDiscountSum = products.reduce((acc, item) => {
        const { discountHtml, productPriceHtml } = item;
        const sign = discountHtml.replace(/[\d]/g, '');

        const price = +getFloatPrice(productPriceHtml);
        if (sign === '%') {
            return acc + ((price * +getFloatPrice(discountHtml)) / HUNDRED);
        }

        return acc + +getFloatPrice(discountHtml);
    }, 0.00);

    // vvv Get final discount depends on discount type
    const sign = products.length > 0 ? products[0].discountHtml.replace(/[\d]/g, '') : '';
    const fDiscount = sign === '%'
        ? (+finalDiscount - finalDiscountSum).toFixed(2)
        : (+finalDiscount + finalDiscountSum).toFixed(2);

    const returnedDiscount = isOnlyOne || isOverConditionalDiscount
        ? parseFloat(0).toFixed(2)
        : fDiscount;

    const returnedFinalPrice = isOnlyOne
        ? mainProductPrice
        : ((+getFloatPrice(finalPriceHtml) + +finalDiscount) - uncheckedProductsSum - +returnedDiscount).toFixed(2);

    return {
        isOnlyOne,
        returnedDiscount,
        returnedFinalPrice,
        isOverConditionalDiscount
    };
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getProducts */
export const getProducts = async (options) => {
    const {
        products: {
            items
        }
    } = await fetchQuery(ProductListQuery.getQuery(options));

    return items;
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getPositions */
export const getPositions = (relatedProducts = [], relatedType) => {
    if (!relatedProducts) {
        return {};
    }

    const { position = '' } = relatedProducts.find((item) => item.type === relatedType) || {};
    const positionArray = position.split('_');

    if (!positionArray.length) {
        return {};
    }

    const [, positionRelateTo, positionPlace] = positionArray;

    return { positionRelateTo, positionPlace };
};

/** @namespace Mana/AmastyAutomaticRelatedProducts/Util/AmastyAutomaticRelatedProducts/getProduct */
export const getProduct = (relatedProducts = [], relatedType) => {
    if (!relatedProducts) {
        return {};
    }

    relatedProducts.find(
        (item) => item.type === relatedType
    );
};
