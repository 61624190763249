/** Component Sidea - Popup selezione pickup store in pagina dettaglio prodotto */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import MapIcon from 'Component/MapIcon';
import Popup from 'Component/Popup';
import { STORE_POPUP_ID } from 'Component/Popup/Popup.config';
import { StoreType } from 'Type/Checkout.type';

import './StorePickupPopup.style';

/** @namespace Frontend/Component/StorePickupPopup/Component */
export class StorePickupPopup extends PureComponent {
    static propTypes = {
        handleSelectStore: PropTypes.func.isRequired,
        handleSelectionReset: PropTypes.func.isRequired,
        storeSearchCriteria: PropTypes.string,
        setStoreSearchCriteria: PropTypes.func.isRequired,
        sku: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        singleStore: PropTypes.string,
        stores: PropTypes.arrayOf(
            StoreType
        )
    };

    static defaultProps = {
        stores: [],
        isLoading: true,
        storeSearchCriteria: '',
        singleStore: ''
    };

    renderNoSearch() {
        const {
            stores,
            storeSearchCriteria
        } = this.props;

        if (storeSearchCriteria.length && !stores.length) {
            return (
                <div block="StorePickup-Popup" elem="EmptyResult">
                    <p>{ __('No results to search') }</p>
                </div>
            );
        }

        return null;
    }

    renderInput() {
        const {
            setStoreSearchCriteria,
            storeSearchCriteria
        } = this.props;

        return (
            <div block="StorePickup-Popup" elem="Search">
                <p block="StorePickup-Popup" elem="InputHeading">{ __('My position') }</p>
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'store-finder',
                      name: 'store-finder',
                      defaultValue: storeSearchCriteria,
                      placeholder: __('City or Postcode')
                  } }
                  events={ {
                      onChange: setStoreSearchCriteria
                  } }
                  mix={ { block: 'StorePickup-Popup', elem: 'Input' } }
                />
                <svg block="StorePickup-Popup" elem="Icon" xmlns="http://www.w3.org/2000/svg" width="19.094" height="19.094" viewBox="0 0 19.094 19.094">
                    <path id="Icon_ionic-md-locate" data-name="Icon ionic-md-locate" d="M11.8,8.387a3.41,3.41,0,1,0,3.41,3.41A3.42,3.42,0,0,0,11.8,8.387Zm8.138,2.5a8.214,8.214,0,0,0-7.229-7.228V2.25H10.888V3.659a8.214,8.214,0,0,0-7.229,7.228H2.25v1.818H3.659a8.214,8.214,0,0,0,7.229,7.229v1.409h1.818V19.935a8.214,8.214,0,0,0,7.229-7.229h1.409V10.888ZM11.8,18.161A6.365,6.365,0,1,1,18.161,11.8,6.383,6.383,0,0,1,11.8,18.161Z" transform="translate(-2.25 -2.25)" fill="#3a3939" />
                </svg>
                { this.renderNoSearch() }
            </div>
        );
    }

    renderProductActiveSku() {
        const { sku } = this.props;

        return sku;
    }

    renderSelectedStoreLocation() {
        const {
            stores,
            isLoading,
            singleStore,
            handleSelectionReset,
            storeSearchCriteria
        } = this.props;

        if (singleStore !== '') {
            return (
                <div
                  block="SingleStore"
                  elem="Selection"
                >
                    <p><strong>{ __('Selected Store') }</strong> { singleStore }</p>
                    <button
                      block="ResetSelection"
                      onClick={ handleSelectionReset }
                      type="button"
                    >
                        <CloseIcon /> { __('Remove selection') }
                    </button>
                </div>
            );
        }

        if (!stores.length && !isLoading && !storeSearchCriteria.length) {
            return (
                <div block="StorePickup" elem="EmptyResult">
                    <p>{ __('No results for your selection') }</p>
                    <p><strong>{ __('Select a product variant') }</strong></p>
                </div>
            );
        }

        return (
            <div
              block="SingleStore"
              elem="Selection"
            >
                <p>{ __('No store selected') }</p>
            </div>
        );
    }

    renderCollect2() {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 20">
                    <path fill="none" stroke="#78A35E" strokeWidth="2" d="m2.13,10.7 4.87,4.87 11.3-11.3" />
                </svg>
                <p>{ __('Available for') }</p>
                <p block="CollectExpress" elem="Note Available">{ __('Pickup in store in 2h') }</p>
            </>

        );
    }

    renderCollect48() {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                    <circle cx="7" cy="7" r="7" transform="translate(2 2)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <path d="M12,6v4.274L14.849,11.7" transform="translate(-2.877 -1.151)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </svg>
                <p>{ __('Available for') }</p>
                <p block="CollectExpress" elem="Note">{ __('Pickup in store in 48h') }</p>
            </>
        );
    }

    renderStore(store) {
        const { singleStore, handleSelectStore } = this.props;
        const {
            name,
            description,
            pickup_location_code,
            street,
            city,
            is_express
        } = store;

        const isExpressClass = is_express ? 'SingleStore CollectExpressAvailable' : 'SingleStore';
        const collectExpressType = is_express ? this.renderCollect2() : this.renderCollect48();
        if (singleStore === name) {
            return null;
        }

        return (
            <div id={ pickup_location_code } block="StorePickup" elem={ isExpressClass }>
                <div block="SingleStore" elem="Info">
                    <p block="StorePickup" elem="Name">{ name }</p>
                    <p block="StorePickup" elem="Address">
                        <MapIcon />
                        <span block="City">{ name } { city }</span> { street }
                    </p>
                    <div block="StorePickup" elem="CollectExpress">
                        { collectExpressType }
                    </div>
                    <div block="SingleStore" elem="Orari">
                        <div block="Orari" elem="Button">{ __('Opening time') }</div>
                        <div block="SingleStore" elem="Description">
                            { /* eslint-disable-next-line react/no-danger */ }
                            <div dangerouslySetInnerHTML={ { __html: description } } />
                        </div>
                    </div>
                </div>
                <button
                  id={ name }
                  block="StorePickup"
                  elem="Selection Button primary-black small"
                  onClick={ handleSelectStore }
                  type="button"
                  data-locationCode={ pickup_location_code }
                  data-isExpress={ is_express }
                >
                    { __('Select for pickup') }
                </button>
            </div>
        );
    }

    renderStoresList() {
        const { stores, isLoading } = this.props;

        if (!stores.length && !isLoading) {
            return null;
        }

        return (
            <div block="StorePickup" elem="List">
                <Loader isLoading={ isLoading } />
                { stores.map(this.renderStore.bind(this)) }
            </div>
        );
    }

    render() {
        return (
            <Popup
              id={ STORE_POPUP_ID }
              mix={ { block: 'StorePickup', elem: 'Popup' } }
            >
                <h2>{ __('Choose the store') }</h2>
                { this.renderSelectedStoreLocation() }
                { this.renderInput() }
                { this.renderStoresList() }
            </Popup>
        );
    }
}

export default StorePickupPopup;
