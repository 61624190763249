/** component Sidea - selezione negozio Collect e modal info spedizione */
import PropTypes from 'prop-types';
import { Component } from 'react';

import StorePickupPopup from 'Component/StorePickupPopup';
import { showSpedInfoPopup, showStorePickupPopup } from 'Util/Popup';

import './ProductCollectShippingMethods.style';

/**
 * @namespace Frontend/Component/ProductCollectShippingMethods/Component
 */
export class ProductCollectShippingMethods extends Component {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        inStock: PropTypes.bool.isRequired,
        singleStore: PropTypes.string,
        selectedStoreIsExpress: PropTypes.bool,
        handleSelectStore: PropTypes.func.isRequired,
        handleSelectionReset: PropTypes.func.isRequired
    };

    static defaultProps = {
        singleStore: '',
        selectedStoreIsExpress: ''
    };

    renderPickupIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="56.021" height="58.8" viewBox="0 0 56.021 58.8">
                <g transform="translate(-1090.488 -859.599)">
                    <path d="M18095.018-353.2a4.179,4.179,0,0,1-2.6-.9l-22.773-13.408a4.181,4.181,0,0,1-2.619-3.889c0-.116,0-.219.008-.323l-.031-.073v-22.519h.037a4.213,4.213,0,0,1,1.668-2.861l.014-.032.2-.114a3.067,3.067,0,0,1,.342-.2l23.064-13.513A4.178,4.178,0,0,1,18095-412a4.149,4.149,0,0,1,2.592.9l23.76,13.9.041.1A4.173,4.173,0,0,1,18123-393.8c0,.1-.006.2-.014.327l.031.068v22.519h-.031a4.224,4.224,0,0,1-1.633,2.834l-.027.064-23.619,13.818A4.246,4.246,0,0,1,18095.018-353.2Zm0-44.514c-.234,0-.457.006-.678.018a9.575,9.575,0,0,0-4.707,1.556,10.3,10.3,0,0,0-3.256,3.5,10.927,10.927,0,0,0-.355,10.211l7.945,17.52a.948.948,0,0,0,.855.537.93.93,0,0,0,.846-.523l8.242-17.379a10.926,10.926,0,0,0-.182-10.215,10.291,10.291,0,0,0-3.2-3.552,9.467,9.467,0,0,0-4.676-1.637c-.213-.017-.453-.027-.754-.032Zm-.008,14.836h-.037a4.224,4.224,0,0,1-3-1.281,4.406,4.406,0,0,1-1.268-3.154,4.383,4.383,0,0,1,1.273-3.084,4.217,4.217,0,0,1,3.029-1.274h.035a4.224,4.224,0,0,1,3.029,1.314,4.4,4.4,0,0,1,1.232,3.12,4.39,4.39,0,0,1-1.27,3.084A4.225,4.225,0,0,1,18095.014-382.879Z" transform="translate(-16976.512 1271.601)" fill="#00ace2" />
                </g>
            </svg>
        );
    }

    renderDeliveryIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="56.056" height="58.8" viewBox="0 0 56.056 58.8">
                <g transform="translate(-1402 -859.601)">
                    <path d="M10132.433-6807.1l-22.789-13.406a4.2,4.2,0,0,1-2.621-3.893c0-.109,0-.216.011-.323l-.033-.073v-22.519h.037a4.187,4.187,0,0,1,1.67-2.861l.013-.03.2-.115c.11-.071.224-.138.342-.2l23.076-13.511a4.182,4.182,0,0,1,2.685-.969,4.182,4.182,0,0,1,2.595.9l23.772,13.9.043.1a4.185,4.185,0,0,1,1.608,3.305q0,.166-.012.329l.03.067v22.519h-.031a4.186,4.186,0,0,1-1.635,2.834l-.028.063-23.634,13.816a4.2,4.2,0,0,1-2.692.972A4.189,4.189,0,0,1,10132.433-6807.1Z" transform="translate(-8705 7724.601)" fill="#00ace2" />
                    <path d="M34.125,19.25H33.25V13.338a2.625,2.625,0,0,0-.771-1.854L27.016,6.021a2.625,2.625,0,0,0-1.854-.771H22.75V2.625A2.626,2.626,0,0,0,20.125,0h-14A2.626,2.626,0,0,0,3.5,2.625V5.25H.438A.439.439,0,0,0,0,5.688v.875A.439.439,0,0,0,.438,7H15.313a.439.439,0,0,1,.438.438v.875a.439.439,0,0,1-.437.438H2.188a.439.439,0,0,0-.437.438v.875a.439.439,0,0,0,.438.438H13.563a.439.439,0,0,1,.437.438v.875a.439.439,0,0,1-.437.438H.438A.439.439,0,0,0,0,12.688v.875A.439.439,0,0,0,.438,14H11.813a.439.439,0,0,1,.438.438v.875a.439.439,0,0,1-.437.438H3.5v7a5.25,5.25,0,0,0,10.5,0h7a5.25,5.25,0,0,0,10.5,0h2.625A.878.878,0,0,0,35,21.875v-1.75A.878.878,0,0,0,34.125,19.25ZM8.75,25.375a2.625,2.625,0,1,1,2.625-2.625A2.626,2.626,0,0,1,8.75,25.375Zm17.5,0a2.625,2.625,0,1,1,2.625-2.625A2.626,2.626,0,0,1,26.25,25.375ZM30.625,14H22.75V7.875h2.412l5.463,5.463Z" transform="translate(1412.528 875)" fill="#f7f7f7" />
                </g>
            </svg>
        );
    }

    renderStorePickupPopup() {
        const {
            sku,
            singleStore,
            handleSelectStore,
            handleSelectionReset
        } = this.props;

        return (
              <StorePickupPopup
                sku={ sku }
                singleStore={ singleStore }
                handleSelectStore={ handleSelectStore }
                handleSelectionReset={ handleSelectionReset }
              />
        );
    }

    renderSelectedStoreName() {
        const {
            singleStore,
            selectedStoreIsExpress
        } = this.props;

        const pickupType = selectedStoreIsExpress === true ? __('Pickup in store 2h') : __('Pickup in store 48h');

        if (singleStore !== '') {
            return (
                <>
                    <p><strong>{ pickupType }</strong></p>
                    <p block="StoreSelection"><strong>{ __('Selected store') }</strong> { singleStore }</p>
                </>
            );
        }

        return (
              <p>{ __('Pickup in store') }</p>
        );
    }

    renderButtonCallAction() {
        const { singleStore, inStock } = this.props;

        if (!inStock) {
            return null;
        }

        if (singleStore !== '') {
            return (__('Change the store'));
        }

        return (__('Choose the store'));
    }

    renderButtonMoreInfo() {
        const { inStock } = this.props;
        const showButtonInfo = true;

        if (!showButtonInfo || !inStock) {
            return null;
        }

        return <button onClick={ showSpedInfoPopup }>{ __('More info') }</button>;
    }

    render() {
        const { inStock } = this.props;
        const deliveryAvailable = inStock ? __('Available for') : __('Not available for');

        return (
            <div
              id="ShippingMethods"
              block="ProductActions"
              elem="ShippingMethods"
            >
                <div
                  id="BoxCollect"
                  block="BoxCollect"
                  elem="Wrapper"
                >
                    { this.renderPickupIcon() }
                    <div block="BoxCollect" elem="info">
                        <span>{ deliveryAvailable }</span>
                        { this.renderSelectedStoreName() }
                        <button onClick={ showStorePickupPopup }>{ this.renderButtonCallAction() }</button>
                    </div>
                </div>
                <div
                  id="BoxShipping"
                  block="BoxShipping"
                  elem="Wrapper"
                >
                    { this.renderDeliveryIcon() }
                    <div block="BoxShipping" elem="info">
                        <span>{ deliveryAvailable }</span>
                        <p>{ __('Home delivery') }</p>
                        { this.renderButtonMoreInfo() }
                    </div>
                </div>
                { this.renderStorePickupPopup() }
            </div>
        );
    }
}

export default ProductCollectShippingMethods;
