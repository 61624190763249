/* eslint-disable jsx-a11y/no-static-element-interactions */
/** override sidea */
import PropTypes from 'prop-types';

import Image from 'Component/Image';
import Loader from 'Component/Loader';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import SourceProductCard from 'SourceComponent/ProductCard/ProductCard.component';

import './ProductCard.override.style';

/** @namespace Frontend/Component/ProductCard/Component */
export class ProductCard extends SourceProductCard {
    static propTypes = {
        ...SourceProductCard.propTypes,
        handleClickColor: PropTypes.func.isRequired,
        smallImage: PropTypes.string
    };

    static defaultProps = {
        ...SourceProductCard.defaultProps,
        smallImage: 'init'
    };

    renderMoreColors() {
        const {
            product: {
                configurable_options = {}
            }
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const { handleClickColor } = this.props;
            const {
                attribute_code,
                values,
                attribute_options
            } = option;

            const moreColors = values.length > 0 ? 'true' : 'false';

            if ((attribute_code == 'color' || attribute_code == 'colore') && moreColors === 'true' && attribute_options !== undefined) {
                const mappedOptionsOnlyValues = Object.values(attribute_options).map((option) => (
                <span
                  onClick={ (e) => handleClickColor(e) }
                  tabIndex="0"
                  role="button"
                  block="ProductCard"
                  value={ option.value }
                  elem="ColorValue"
                  style={ { backgroundColor: option.swatch_data.value, borderColor: option.swatch_data.value == '#ffffff' ? '#c7c4c4' : '' } }
                />
                ));

                return (
                    <div block="MoreColors" elem="Label">
                        { mappedOptionsOnlyValues }
                    </div>
                );
            }

            return null;
        });
    }

    renderCardBrand(visible = true) {
        if (visible) {
            return this.renderBrand();
        }

        return null;
    }

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail, smallImage } = this.props;

        const imageUrl = smallImage === 'init' ? thumbnail : smallImage;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ imageUrl }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ imageUrl }
                />
            </>
        );
    }

    renderCardContent() {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    { this.renderLabelNew() }
                    { this.renderLabelSale() }
                    { this.renderLabelComingSoon() }
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        <div block="ProductCard" elem="ProductInfo">
                            { this.renderCardBrand(true) }
                            { this.renderName(false) }
                        </div>
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }

    renderVisibleOnHover() {
        const { device } = this.props;

        if (device.isMobile) {
            return (
                <div block="ProductCard" elem="Footer">
                    { this.renderProductCardWishlistButton() }
                </div>
            );
        }

        return (
                <div block="ProductCard" elem="Footer">
                    { this.renderProductCardWishlistButton() }
                </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderMoreColors() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCard;
