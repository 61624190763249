/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import AmConfigQuery from '../../query/AmConfig.query';

const addAmastyConfigToState = (args, callback) => ({
    ...callback(...args),
    amAutomaticRelatedProducts: {}
});

const getAmastyConfigFromAction = (args, callback) => {
    const [, action] = args;
    const { type, config: { amAutomaticRelatedProducts } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        amAutomaticRelatedProducts
    };
};

const extendConfigQueryWithAmastyConfig = (args, callback) => ([
    ...callback(...args),
    AmConfigQuery.getQuery()
]);

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addAmastyConfigToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getAmastyConfigFromAction
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: extendConfigQueryWithAmastyConfig
        }
    }
};
