/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './Messages.style';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/Messages/Component/MessagesComponent */
export class MessagesComponent extends PureComponent {
    static propTypes = {
        packId: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired
    };

    renderMessage() {
        const { packId, message } = this.props;

        return (
            <div block="Messages" elem="CartMessage" id={ packId }>
                <Html content={ message } />
            </div>
        );
    }

    render() {
        return (
            <div block="Messages">
                { this.renderMessage() }
            </div>
        );
    }
}

export default MessagesComponent;
