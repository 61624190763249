/**
 * Amasty Automatic Related Products compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ProductActions from 'Component/ProductActions';
import ProductActionsComponent from 'Component/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';

import './ProductOptions.style';

/** @namespace Mana/AmastyAutomaticRelatedProducts/Component/ProductOptions/Component/ProductOptionsComponent */
export class ProductOptionsComponent extends ProductActionsComponent {
    static propTypes = {
        ...ProductActions.PropTypes
    };

    // vvv Remove review button on popup to not execute another popup
    renderReviewButton() {
        return null;
    }

    renderAddToCartActionBlock() {
        return (
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderQuantityChanger() }
                <div block="ProductActions" elem="ActionButtons">
                    { this.renderWishlistButton() }
                    { this.renderCompareButton() }
                </div>
            </div>
        );
    }

    renderAddToCartMobile() {
        return (
            <div
              block="ProductActions"
              elem="AddToCartFixed"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderQuantityChanger() }
                { this.renderWishlistButton() }
            </div>
        );
    }

    render() {
        const { device: { isMobile } = {}, setValidator } = this.props;
        const render = isMobile ? this.renderMobile() : this.renderDesktop();

        return (
            <article block="ProductActions" ref={ (elem) => setValidator(elem) }>
                { render }
            </article>
        );
    }
}

export default ProductOptionsComponent;
